import React from 'react';
import { HeaderHalfNBC } from '../essentials/header/header-half-resources.component';
import { NavBar } from '../essentials/navbar/navbar.component';
import { Footer } from '../essentials/footer/footer.component';
import moment from 'moment';
import './blog.component.scss'

export const Blog = ({ blogs, ...props }) => {
  let blogList = blogs.filter(blog => blog.slug === props.match.params.slug);
  let blog;
  let BASE_URL = process.env.REACT_APP_API_URL;

  if (blogs == null || blogs.length <= 0) {
    blog = fetch(`${BASE_URL}/blogs/${props.match.params.slug}`);
  } else {
    blog = blogList[0];
  }
  window.scrollTo(0,0);
  const url = process.env.REACT_APP_API_URL + '/img/blogs/';
  return (
    <div className='blog-main-1'>
      <NavBar />
      <HeaderHalfNBC title='Do You Enjoy a Good Read?' uris={`blog / ${blog.title}`} />
      <div className='overlap-box blog-content'>
        <div className='blog-i-header'>
          <h1 className='blog-i-title'>{blog.title}</h1>
          <div className='RectangleBox'></div>
          <p>By {blog.author} | {moment(blog.publishedAt).format("YYYY-MM-DD")}</p>
          <img
            src={`${url}${blog.coverImage}`}
            alt='blogImg'
            //width='50%'
            //height='10%'
            className='blog-img'
          />
          {/* <p>{blog.subject}</p> */}
        </div>
        <div className='articleTextBlock' dangerouslySetInnerHTML={{ __html: blog.article }}></div>
      </div>
      <Footer />
    </div>
  );
};
