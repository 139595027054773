import React, { useState, useCallback } from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
// import prize from '../../../assest/17 - About - Partners/1stprize.png';
import logo_gba from '../../../assest/17 - About - Partners/logo_gba.png';
import logo_hedera from '../../../assest/17 - About - Partners/logo_hedera.png';
import logo_felix from '../../../assest/17 - About - Partners/logo_felix.png';
// import logo_intelliqua from '../../../assest/17 - About - Partners/logo_intelliaqua.png';
//import logo_blockapps from '../../../assest/17 - About - Partners/logo_blockapps.png';
// import logo_transac from '../../../assest/17 - About - Partners/logo_transac.png';
// import logo_localhero from '../../../assest/17 - About - Partners/logo_Localhero.png';
// import logo_rejolut from '../../../assest/17 - About - Partners/logo_Rejolut.png';
// import logo_nelf from '../../../assest/17 - About - Partners/logo_nelf.png';
// import logo_upciclo from '../../../assest/17 - About - Partners/logo_upciclo.png';
// import logo_3r from '../../../assest/17 - About - Partners/logo_3R.png';
// import logo_upride from '../../../assest/17 - About - Partners/logo_upride.png';
import logo_euroyen from '../../../assest/17 - About - Partners/euroyen.png';
import logo_fujitsu from '../../../assest/17 - About - Partners/Fujitsu.jpg';
import logo_hera from '../../../assest/17 - About - Partners/hera.jpg';
import logo_techmahindra from '../../../assest/17 - About - Partners/tech.jpg'
import logo_mindtree from '../../../assest/17 - About - Partners/mindtree.jpg'
//import logo_goodHealthPass from '../../../assest/17 - About - Partners/Good Health.jpg'
import logo_icca from '../../../assest/17 - About - Partners/ICCA.png'
import logo_mass from '../../../assest/17 - About - Partners/MassChallenge.png'
import logo_emphasis from '../../../assest/17 - About - Partners/mphasis.png'
import logo_barclay from '../../../assest/17 - About - Partners/RGA Primary Badge_Black Text.png'
import logo_london from '../../../assest/17 - About - Partners/London_&_Partners_logo.png'
import logo_nasscom from '../../../assest/17 - About - Partners/nasscom.png'
import logo_ukri from '../../../assest/17 - About - Partners/UKRI.png'
import logo_solvecare from '../../../assest/17 - About - Partners/Solvecare.png'


import ResponseModal from "../../../components/requestsalescomponents/ResponseModal";
import './partners.styles.scss';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import * as yup from 'yup';

const schema = yup.object({
  contact_name: yup.string().required('Name is required'),
  organization: yup.string().required('Company name is required'),
  contact_email: yup.string().required('E-mail is required').email('Invalid email'),
  contact_title: yup.string().required('Designation is required')
});


export const PartnerPage = props => {
  let BASE_URL = process.env.REACT_APP_API_URL;
  const history = useHistory();

  const [message, setMessage] = useState(null);
  const [showResponseModal, setResponseModal] = useState(false);
  const handleClose = () => {
    setResponseModal(false);
    history.push('/');
  };
  const onSubmit = useCallback(async function onFormSubmit(values) {
    try {
      const response = await fetch(`${BASE_URL}partners`, {
        // Adding method type
        method: 'POST',
        // Adding headers to the request
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        // Adding body or contents to send
        body: JSON.stringify(values),
      });
      if (response.ok) {
        setMessage('Thank you for your request. Our team will get back to you soon.');
      } else {
        setMessage('Oops! Seems some issue submitting your request. Please contact us over email.');
      }
    } catch (error) {
      setMessage('Alas! Seems some issue submitting your request. Please contact us over email.');
    }
    setResponseModal(true);
  }, []);
  return (
    <div className='blogs-main'>
      <NavBar />
      <HeaderHalf title='Partners' uris='Partners' />
      <OverlapPartner title="Customers & Partners">
        <div className='content-partners'>
        
        <PartnersContent title="" classN='logo-partners' />
   
          <div className="events-register">
            <h3 className="title" id="registerSectionForEvent">Become Our Partner!</h3>
            <p className="subtitle lead mt-4">
              Journey with EarthID is an ever-running road to learning & excellence.</p>
            <Formik validationSchema={schema} initialValues={{}} onSubmit={onSubmit}>
              {({ handleSubmit, handleChange, values, errors, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      type='text'
                      name='contact_name'
                      maxLength='25'
                      value={values.contact_name}
                      onChange={handleChange}
                      isInvalid={!!errors.contact_name}
                    />
                    <Form.Control.Feedback type='invalid' tooltip>
                      {errors.contact_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>E-mail*</Form.Label>
                    <Form.Control
                      type='email'
                      name='contact_email'
                      maxLength='50'
                      value={values.contact_email}
                      onChange={handleChange}
                      isInvalid={!!errors.contact_email}
                    />
                    <Form.Control.Feedback type='invalid' tooltip>
                      {errors.contact_email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Company Name*</Form.Label>
                    <Form.Control
                      type='text'
                      name='organization'
                      maxLength='100'
                      value={values.organization}
                      onChange={handleChange}
                      isInvalid={!!errors.organization}
                    />
                    <Form.Control.Feedback type='invalid' tooltip>
                      {errors.organization}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control
                      type='text'
                      name='contact_title'
                      maxLength='30'
                      value={values.contact_title}
                      onChange={handleChange}
                      isInvalid={!!errors.contact_title}
                    />
                    <Form.Control.Feedback type='invalid' tooltip>
                      {errors.contact_title}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant='primary' block type='submit' disabled={isSubmitting}>
                    {isSubmitting ? 'SUBMITTING...' : 'SUBMIT'}
                  </Button>
                </Form>
              )}
            </Formik>
            <ResponseModal message={message} show={showResponseModal} handleClose={handleClose} />
          </div>
        </div>
      </OverlapPartner>
      <Getstarted/>
      <Footer />
    </div>
  );
};
export const OverlapPartner = props => {
  return (
    <div className='overlap-partner-box'>
      <p className='box-title-1'>{props.title}</p>
      {props.children}
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-partner'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          About / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};


export const PartnersContent = ({ title, classN }) => {
  return (
    <div className='partners-block'>
      <h3 className='partner-title'>{title}</h3>
      <div className={classN}>
      <img src={logo_icca} className='partnerLogo' />
        <img src={logo_emphasis} className='partnerLogo' />
        <img src={logo_london} className='partnerLogo' />
        <img src={logo_nasscom} className='partnerLogo' />
        <img src={logo_ukri} className='partnerLogo' />
        <img src={logo_barclay} className='partnerLogo' />
        <img src={logo_mass} className='partnerLogo' />
        <img src={logo_solvecare} className='partnerLogo' />
        <img src={logo_gba} className='partnerLogo' />
        <img src={logo_hedera} className='partnerLogo' />
        <img src={logo_felix} className='partnerLogo' />
        {/* <img src={logo_intelliqua} className='partnerLogo' /> */}
        {/* <img src={logo_blockapps} className='partnerLogo' /> */}
        {/* <img src={logo_transac} className='partnerLogo' />
        <img src={logo_localhero} className='partnerLogo' />
        <img src={logo_rejolut} className='partnerLogo' />
        <img src={logo_nelf} className='partnerLogo' />
        <img src={logo_upciclo} className='partnerLogo' />
        <img src={logo_3r} className='partnerLogo' />
        <img src={logo_upride} className='partnerLogo' /> */}
        <img src={logo_euroyen} className='partnerLogo' />

        <img src={logo_fujitsu} className='partnerLogo' />
        <img src={logo_techmahindra} className='partnerLogo' />
        <img src={logo_mindtree} className='partnerLogo' />
        {/* <img src={logo_goodHealthPass} className='partnerLogo' /> */}
        <img src={logo_hera} className='partnerLogo' />
      </div>
    </div>
  );
}