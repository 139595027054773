import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { OverlapWithFilter } from '../../../components/essentials/overlap-box/overlap-with-filters/overlap-with-filters.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import '../blogs/blogs.styles.scss';
import './webinars.styles.scss';
// import coming_soon from '../../../assest/21 - Resources - Webinars – 1/Coming Soon.png';

import { NavBar } from '../../../components/essentials/navbar/navbar.component';
//import { Pagination } from '@material-ui/lab';
import { Popup } from '../../../components/resources/pop.component';


export const Webinars = props => {
  const { webinars, updatePage, loading, ...otherProps } = props;
  const [currentEventsPage, setCurrentEventsPage] = useState(1);
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  /**
   * Submit Register Form
   */


  /*const handleChange = (e, newPage) => {
    updatePage(newPage); 
  };*/

  return (
    <div className='blogs-main'>
      <NavBar />
      <HeaderHalf title='Events' uris='Events' />
      <OverlapWithFilter>
        <div className='event-tab'>
          <button onClick={() => setCurrentEventsPage(1)} className={`event-btn_1 ${currentEventsPage == 1 ? 'active' : ''}`}>
            <Link className='elinks'>
              <p>upcoming</p>
            </Link>
          </button>
          <button onClick={() => setCurrentEventsPage(2)} className={`event-btn_1 ${currentEventsPage == 2 ? 'active' : ''}`}>
            <Link className='elinks'>
              <p>past</p>
            </Link>
          </button>
        </div>
        <div className='grid-for-webinars'>
          {loading === true ? (
            <h1>Loading the content</h1>
          ) : (
              /**
               * TODO: USE ONLY WEBINARS ONCE GET DATA FROM API
               */
              webinars.map(webinar => (
                <WebinarCard
                  key={webinar.id}
                  title={webinar.title}
                  img={webinar.coverImage != null ? webinar.coverImage : "/" + 'Coming Soon.png'}
                  url={webinar.url}
                  date={webinar.eventDate}
                  months={monthNames}
                  section={currentEventsPage}
                  recording={typeof webinar.recording != 'undefined' ? webinar.recording : "https://youtu.be/000iRYsGHtTFM"}
                  {...otherProps}
                />
              ))
            )}
        </div>
      </OverlapWithFilter>



      <Popup popup={otherProps.popup} setPopup={otherProps.setPopup}></Popup>
      <Getstarted/>
      <Footer />
    </div>
  );
};
const WebinarCard = ({ img, title, url, date, months, section, recording, ...otherProps }) => {
  const { setPopup } = otherProps;
  const urlWeb = process.env.REACT_APP_IMG_EVENT_URL;

  const d = new Date(date);
  const today = new Date();

  const openPopup = e => {
    // console.log('I am open pop up!:', otherProps.popup, 'I am a url', console.log(url));
    setPopup({ show: true, url: recording });
  };

  const redirectPage = e => {
    if (url != 'null') {
      window.open(url, "_blank")
    }
  };


  let sectionType = (d.getTime() + 86400000) > today.getTime() ? 1 : 2;

  if (section !== sectionType) { return ""; }

  return (
    <div className='blog-div-card'>
      <div>
        <img src={`${urlWeb}${img}`} alt='webImg' className='img-for-webinar' />
        {/* <p className='top-banner'>on-demand webinar</p> */}
        <p className='title-for-webinar'>{title}</p>
        <p className="date-for-webinar text-uppercase">{months[d.getMonth()]} {d.getDate()}, {d.getFullYear()}</p>
      </div>
      <button className='btn-webinar text-uppercase' onClick={sectionType === 2 ? openPopup : redirectPage}>
        {sectionType === 1 ? url == 'null' ? "COMING SOON" : "REGISTER NOW" : "Watch Now"}
      </button>
    </div>
  );
};

//webinar
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-webinar'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Resources / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
