import React from 'react';
import './platform.styles.scss';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import passwordless from '../../../assest/2 - Solutions - Platforms/search-problem.svg';
import ai from '../../../assest/2 - Solutions - Platforms/padlock (1).svg';
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import photoOnPhone from '../../../assest/2 - Solutions - Platforms/EarthId-KYS-01.png';
import { FaceId } from '../../../components/homepageComponents/face-id/face-id.component';

export const Platform = () => {
  return (
    <div className='solution-platform'>
      <NavBar />
      <HeaderHalf title='Platform' uris='Platform' />
      <OverlapBox title='Establishing Trust With Our Global Identity Solutions'>
        <div className='platform-content'>
          <p className='cards-div-title'>
            With technologies improving day by day, regulations evolving, and customers 
            looking for faster and more efficient identity solutions, your identity partner needs to be 
            adaptable. EarthID effortlessly adopts state-of-the-art technology, keeps up with compliance, 
            at the same time giving users the best-optimized user experience,
        </p>
          <div className='cards-div-platform'>
            <MediumCard
              icon={kysBulletPoint}
              title='Accelerate Business Growth'
              desc='Grow your business with quality customers by preventing fraudulent accounts, ensuring compliance, and improving the speed of identity verification.'
            />
            <MediumCard
              icon={passwordless}
              title='Prevent Identity Fraud'
              desc='With EarthID’s AI-powered biometrics and state-of-the-art technology avoid identity frauds and stop fraudsters to enter your business ecosystem.'
            />
            <MediumCard
              icon={ai}
              title='Meet Regulatory Compliance'
              desc='Meet Know Your Customer (KYC) and Anti-Money Laundering (AML) requirements by complying with regulations affecting your business'
            />
          </div>
        </div>
      </OverlapBox>

      <div className='face-scan-adobe_1'>
        <FaceId />
      </div>


      <OverlapBox title='EarthID’s KYS Platform'>
        <EarthidBox />
      </OverlapBox>
      <Getstarted/>
      <Footer />
    </div>
  );
};

const EarthidBox = props => {
  return (
    <div className='kys-div'>
      <div className='kys-platform-content'>
        <KYSCard icon={kysBulletPoint} title='Onboarding Friction'
          desc='With EarthID’s AI-Powered Identity solution, quickly onboard users without having to give up on security.'
        />
        <KYSCard icon={kysBulletPoint} title='Technologically Advanced'
          desc='EarthID is powered by cutting edge technologies like Artificial Intelligence and Distributed Ledger Technology.'
        />

        <KYSCard icon={kysBulletPoint} title='User Experience'
          desc="EarthID is intelligently designed to provide its users with a swift and effortless user-friendly verification process."
        />
      </div>
      <div className='kys-platform-content'>
        <KYSCard icon={kysBulletPoint} title='Global Compliance'
          desc='EarthID is Global Data Regulation Compliant to give all its users borderless access to global services.'
        />

        <KYSCard icon={kysBulletPoint} title='Customer Conversion'
          desc='Bid goodbye to time-consuming and complex onboarding processes and significantly reduce customer abandonment rates.'
        />
        <KYSCard icon={kysBulletPoint} title='Trust & Safety'
          desc='With EarthID’s secure identity solutions, inspire confidence in your brand and users who transact on your platform.'
        />
      </div>
      <div className='kys-platform-img'> <img src={photoOnPhone} className='kys-platform-image' alt='kysCardIcon' /></div>

    </div>


  );
};

export const KYSCard = ({ icon, desc, title }) => {
  return (
    <div className='kys-card'>
      <div className="key-icon-col"><img src={icon} className='kys-card-icon' alt='kysCardIcon' /></div>
      <div className="key-text-col">
        <p className='kys-card-title'>{title}</p>
        <p className='kys-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    </div>
  );
};

export const MediumCard = ({ icon, desc, title }) => {
  return (
    <div className='medium-card no-bottom-padding'>
      <img src={icon} className='medium-card-icon-platform' alt='mediumCardIcon' />
      <p className='medium-card-title'>{title}</p>
      <p className='medium-card-desc-platform'>{!desc ? 'right now description is not available' : desc}</p>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-platform'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
}; 
