import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import phoneImg from '../../../assest/wallet/Intro (1).jpg';
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import photoOnPhone from '../../../assest/wallet/Features.png'
import benefits from '../../../assest/wallet/Benefits (1).jpg'
import './wallet.scss';
//import { OverlapBoxTechno } from '../../technopage/techno.component';
import {  OverlapBoxFeature } from '../features-page/features-page.component';

import tobacco from '../../../assest/wallet/icons (1)/FS.png';
import gamble from '../../../assest/wallet/icons (1)/Edu.png';
import alcohol from '../../../assest/wallet/icons (1)/PS.png';
import cbd from '../../../assest/wallet/icons (1)/Health.png';
import content from '../../../assest/wallet/icons (1)/Smart.png';
import pharma from '../../../assest/wallet/icons (1)/Retail.png';
import diy from '../../../assest/wallet/icons (1)/Travel.png';

import global from '../../../assest/wallet/Integration Square.jpg'

//import {  MediumCard } from '../platformpage/platform.component';
//import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';

export const Wallet = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='ID Wallet' uris='ID Wallet' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Future-Proof Identity Management for You and Your Customers'
    >
      <p>
      EarthID&apos;s ID Wallet enables you to integrate secure, decentralized, and privacy-preserving identity management directly into your apps, offering a seamless solution for managing and sharing verifiable credentials. With the embedded wallet, you can streamline identity verification (IDV) and onboarding, providing customers with instantly verifiable data, reusable credentials, and passwordless authentication. You retain full control over your customer experience while ensuring compliance with global privacy regulations like eIDAS and GDPR.
      </p>
      <p>
      The wallet empowers your customers by giving them control over their verifiable credentials, with purposeful consent and selective disclosure, allowing them to choose when and how to share their data. With seamless authentication, fraud prevention, and enhanced data privacy, they can securely access services both online and in person.
      </p>
      <p>
      With quantum-resistant security and flexible custody options, EarthID&apos;s ID Wallet is a future-proof, privacy-preserving solution, offering scalability and compliance, helping you meet the demands of today&apos;s rapidly evolving digital landscape.
      </p>
    </OverlapBoxTechno>
    <OverlapBoxFeature title='Key Features'>
        <MoreBox />
      </OverlapBoxFeature>
      <OverlapBoxIndustry title='Industry Use Cases' className=' overlap-box-industry'>
        {/* <p className='industries-text'>
        Age verification is crucial for businesses that provide age-restricted products and services. 
        Whether you are offering online gaming, adult content, alcohol sales, or any other service that requires age restrictions, verifying the age of your users is a legal necessity. However, traditional age verification methods often require sensitive personal information, which can be a deterrent for users concerned about their privacy. 
        Secure your transactions responsibly and confidently with EarthID&apos;s age verification solutions.
        </p> */}
        <div className='cards-div-industries'>
          <LargeCard
            img={tobacco}
            title='Financial Services – Customer Wallet'
            desc="Enable secure ID verification (IDV), passwordless authentication, and credential management for customers across banking and financial services. The wallet ensures secure transactions and fraud prevention while improving customer convenience and regulatory compliance."
          />
          <LargeCard
            img={gamble}
            title='Education – Student Wallet'
            desc="Provide students with a digital wallet for secure management of academic records, passwordless access to educational services, and identity verification. Streamline student onboarding, enhance privacy, and simplify service access."
          />
          <LargeCard
            img={alcohol}
            title='Public Services – Citizen Wallet'
            desc="Equip citizens with a wallet for identity verification and secure access to public services like benefits, voting, and tax filings. Ensure compliance with data privacy regulations while maintaining seamless interactions."
          />
          <LargeCard
            img={cbd}
            title='Healthcare – Patient Wallet'
            desc="Allow patients to securely manage healthcare credentials and prescriptions, access medical records, and authenticate with passwordless logins. Improve data security, privacy, and ease of access to healthcare services."
          />
          <LargeCard
            img={content}
            title='Smart Cities – Resident Wallet'
            desc="Implement resident wallets for managing city services such as transportation, utilities, and smart infrastructure. The wallet enables passwordless access and identity verification, enhancing citizen engagement and service efficiency."
          />
          <LargeCard
            img={pharma}
            title="Retail/E-Commerce – Shopper Wallet"
            desc="Offer a shopper wallet for ID verification, passwordless checkout, and managing loyalty credentials. Streamline the shopping experience, reduce fraud, and enhance user satisfaction in retail and e-commerce platforms."
          />
          <LargeCard
            img={diy}
            title='Travel & Hospitality – Traveler Wallet'
            desc='Provide travelers with a digital wallet for secure ID verification, passport and ticket management, and passwordless authentication for booking and check-ins, enhancing the travel experience with reduced friction and improved security.'
          />
                 
        </div>
      </OverlapBoxIndustry>
      <OverlapBoxFeature  title='Solution Benefits'>
        <EarthidBox />
      </OverlapBoxFeature>
      
      <SectionRight2
        title="Seamless Integration with EarthID’s ID Wallet"
        content="Integrating EarthID’s ID Wallet into your platform is designed to be effortless and adaptable to your infrastructure. Here’s how it works:"
        title1="Lightweight SDKs"
        content1= "EarthID provides lightweight SDKs for both iOS, Android, and web platforms, designed for quick and smooth integration into your existing applications with minimal development effort. These SDKs enable you to seamlessly offer your users secure, decentralized identity verification without the need for extensive backend modifications."
        
        title2="RESTful APIs"
       content2= "EarthID’s RESTful APIs simplify the integration of wallet functionalities into your system. These APIs support identity verification, authentication, and secure data sharing. They ensure real-time, secure interaction with your system while enhancing user privacy and compliance with evolving regulations."
       
       title3="Standalone App"
       content3= "For organizations looking for a faster implementation, EarthID offers the option of a standalone white-labeled app. This approach allows you to quickly pilot EarthID’s wallet solution without altering your existing infrastructure. You can customize branding, UI/UX, and deploy with ease."
       
       title4="Advanced Security & Compliance"
       content4= "EarthID’s integration solutions meet the highest standards of security and compliance, including ISO 27001, GDPR, UKDIATF, and PAS 1296 certifications. This ensures that your data is handled with the utmost security, safeguarding against modern threats while maintaining full regulatory compliance."
       
       
       imgPath={global}
      ></SectionRight2>
     
    <Getstarted/>
    <Footer />
  </div>
);

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-distributee'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};

// const KDTCard = ({ icon, desc, title }) => {
//   return (
//     <div className='kys-card'>
//       <div ><img src={icon} className='kdt-card-icon' alt='kysCardIcon' /></div>
//       <div >
//         <p className='kys-card-title'>{title}</p>
//         <p className='kdt-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
//       </div>
//     </div>
//   );
// };

const MoreBox = props => {
    return (
      <div className='kidv-div'>
<div className='kys-platform-img-top'>
        <img src={photoOnPhone} className='kys-platform-image' alt='EarthID Image' />
      </div>
      <div className='kys-div'>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Secure Credential Management'
            desc='Provide your customers with a safe and decentralized environment to store, manage, and share personal credentials, ensuring privacy, security, and regulatory compliance. By integrating EarthID&apos;s ID wallet, you streamline credential management, reducing fraud risks while maintaining full control over the user experience.'
          />
          <KYSCard icon={kysBulletPoint} title='Reuse of Verified Data'
            desc="Enable your customers to securely reuse their verified identity and data across multiple services, reducing the need for repeated verifications. This not only accelerates onboarding but ensures a consistent, secure experience for customers across platforms. By adopting EarthID&apos;s ID wallet, you enhance operational efficiency, while maintaining data integrity and adhering to privacy regulations. This creates a seamless, efficient interaction for both you and your customers."
          />
          <KYSCard icon={kysBulletPoint} title='Selective Disclosure & Purposeful Consent'
            desc='Empower your customers to share only the necessary information by enabling selective disclosure. This allows them to control which parts of their data are shared with third parties, reducing unnecessary exposure and safeguarding privacy. Combined with purposeful consent, your customers actively decide when and how their data is used, ensuring transparency and trust.'
          />
        </div>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Unified Communication Channel'
            desc='Provide a secure and unified communication channel between you and your customers, significantly reducing the risk of phishing and impersonation attacks. This channel ensures that all communications are securely transmitted through the wallet, preventing third-party interception or malicious actors from manipulating sensitive data.'
          />
          <KYSCard icon={kysBulletPoint} title='Passwordless Authentication'
            desc='You can eliminate passwords entirely, giving your customers a frictionless and secure way to access your services. By integrating EarthID&apos;s ID wallet, you reduce the risks of credential theft, phishing, and password fatigue, while offering an effortless experience. This approach not only strengthens your security but also simplifies the customer journey, boosting satisfaction and trust.'
          />
          <KYSCard icon={kysBulletPoint} title='Compliance with Global Standards'
            desc='Stay compliant with evolving regulations like eIDAS and GDPR by minimizing data collection and eliminating the need for centralized databases. EarthID&apos;s ID wallet is fully compliant with global standards, including eIDAS, GDPR, and W3C DID, ensuring your business adheres to the latest legal and regulatory requirements. This robust compliance framework guarantees that your customer data is handled with the highest standards of privacy and security.'
          />
        </div>
        
      </div>
  </div>
  
    );
  };

  const OverlapBoxIndustry = props => {
    return (
      <div className='overlap-boxx'>
        <p className='box-title'>{props.title}</p>
        {props.children}
      </div>
    );
  };

  const LargeCard = ({ img, desc, title }) => {
    return (
      <div className='largeind-card'>
        <img src={img} className='large-card-img' alt='largeCardImg' />
        <p className='large-card-title'>{title}</p>
        <p className='large-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    );
  };

  const EarthidBox = props => {
    return (
      <div className='kidv-div'>
<div className='kys-platform-img-top'>
        <img src={benefits} className='kys-platform-image' alt='EarthID Image' />
      </div>
      <div className='kys-div'>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Seamless Integration'
            desc='Easily integrate EarthID’s ID Wallet into your existing app, enhancing your service offering while retaining full control over the customer experience.'
          />
          <KYSCard icon={kysBulletPoint} title='Operational Efficiency'
            desc='Maximize operational efficiency by leveraging reusable verified data and passwordless authentication.'
          />
  
          <KYSCard icon={kysBulletPoint} title='Fraud Prevention'
            desc="Prevent identity theft and fraud using decentralized, privacy-preserving technology that leverages data minimization."
          />
           <KYSCard icon={kysBulletPoint} title='Regulatory Confidence'
            desc='Ensure compliance with global privacy regulations like GDPR, DPDP, CCPA and eIDAS, supporting expansion with reduced legal risks.'
          />
          <KYSCard icon={kysBulletPoint} title='Global Coverage & Accessibility'
            desc='Scale effortlessly across regions, maintaining compliance with global regulations.'
          />
        </div>
        <div className='kys-platform-content'>
         
  
        <KYSCard icon={kysBulletPoint} title='Customer Trust'
            desc='Enhance trust through privacy-preserving technology and customer-controlled data sharing.'
          />
          <KYSCard icon={kysBulletPoint} title='Scalability for Growth'
            desc='Easily scale as your business and customer base grow, ensuring sustained performance and security.'
          />
          <KYSCard icon={kysBulletPoint} title='Cost Savings'
            desc='Cut costs by automating identity verification and reducing repetitive onboarding tasks.'
          />
          <KYSCard icon={kysBulletPoint} title='Competitive Edge'
            desc='Stay ahead with a cutting-edge, decentralized identity solution, setting your organization apart from competitors.'
          />
          <KYSCard icon={kysBulletPoint} title='Interoperability'
            desc='EarthID’s wallet ensures compatibility with existing infrastructure and standards, allowing data to be securely shared and trusted across multiple platforms.'
          />
        </div>
       
      </div>
  
  </div>
    );
  };


  const SectionRight2 = props => {
    return (
      <div className='feature-box-rights'>
        <p className='title-feature'>{props.title}</p>
        <p className='desc1-feature'>{props.desc}</p>
        <div className="kys-div">
          <div className='kys-platform-content'>
  
              {/* Conditionally render KDTCard if both title1 and content1 exist */}
              {props.title1 && props.content1 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title1}
                  desc={props.content1}
                />
              )}
  
              {/* Conditionally render KYSCard if both title2 and content2 exist */}
              {props.title2 && props.content2 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title2}
                  desc={props.content2}
                />
              )}
  
              {/* Conditionally render KYSCard if both title3 and content3 exist */}
              {props.title3 && props.content3 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title3}
                  desc={props.content3}
                />
              )}
  
              {/* Conditionally render KYSCard if both title4 and content4 exist */}
              {props.title4 && props.content4 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title4}
                  desc={props.content4}
                />
              )}
  
              {/* Conditionally render KYSCard if both title5 and content5 exist */}
              {props.title5 && props.content5 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title5}
                  desc={props.content5}
                />
              )}
  
              {/* Conditionally render KYSCard if both title6 and content6 exist */}
              {props.title6 && props.content6 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title6}
                  desc={props.content6}
                />
              )}
  
              {/* Conditionally render KYSCard if both title7 and content7 exist */}
              {props.title7 && props.content7 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title7}
                  desc={props.content7}
                />
              )}
  
              {/* Conditionally render KYSCard if both title8 and content8 exist */}
              {props.title8 && props.content8 && (
                <KYSCard
                  icon={kysBulletPoint}
                  title={props.title8}
                  desc={props.content8}
                />
              )}
  
            </div>
            <div className={`kys-platform-imgdt`}>
            <img src={props.imgPath} className='kys-platform-imageedt' alt="feature-img" />
          </div>
         
  
         
        </div>
      </div>
    );
  };
  



 const KYSCard = ({ icon, desc, title }) => {
    return (
      <div className='kys-card'>
        <div c><img src={icon} className='kdt-card-icon' alt='kysCardIcon' /></div>
        <div className="key-text-col">
          <p className='kys-card-title'>{title}</p>
          <p className='kdt-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
        </div>
      </div>
    );
  };

 const OverlapBoxTechno = ({ img, title, children }) => {
    return (
      <div className='overlap-box-technoo'>
        <img src={img} alt='phone-img' className='techno-imgDT' />
        <div className='techno-content'>
          <p className='techno-title'>{title}</p>
          <div className='rest-para'>{children}</div>
        </div>
      </div>
    );
  };


