import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import cancel from '../../assest/Home/cancel.png';
import gplay from '../../assest/playstore.png';
import iApp from '../../assest/appstore.png';

import './downloadapp.pop.styles.scss';

export const DwnloadAppPopUp = ({ popup, setPopup }) => {
  const handleChange = e => {
    setPopup({ show: false });
  };

  return (
    <Dialog open={popup.show} maxWidth={'md'}>
      <DialogContent className='download-app-form'>
        <div>
          <div>
            <button className='cancel-btn' onClick={handleChange}>
              <img src={cancel} alt='toCncl' className='cancel-img' />
            </button>
          </div>
          <div className='app_buttons'>
            <a href="https://play.google.com/store/apps/details?id=com.myearthid" target="BLANK"><img src={gplay} alt='toCncl' className="app-img" /> </a>
            <a href="https://apps.apple.com/us/app/earthid/id1479685319" target="BLANK"><img src={iApp} alt='toCncl' className="app-img" /> </a>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};