import React, { useState, useEffect }  from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import '../resourcespage/resources.styles.scss';
import { LatestNews } from './news/news.component';
import { PartnerPage } from './partners/partners.component';
import { AboutEarthId } from './earthid/about-earthid.component';

const AboutPage = () => {
  let { path } = useRouteMatch();
  let BASE_URL = process.env.REACT_APP_API_URL;
  const [newsCards, setNewsCards] = useState([]);
  const [newsCardPage, setNewsCardPage] = useState(1);
  const [pressCards, setPressCards] = useState([]);
  const [pressCardPage, setPressCardPage] = useState(1);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchFunc = async () => {
      setLoading(false);
      const response = await fetch(`${BASE_URL}/news?limit=25&page=${newsCardPage}`);
      const items = await response.json();
      // const items = resJson.data.blogs;
      setNewsCards(items);
      setLoading(false);
    };

    fetchFunc();
  }, [newsCardPage]);
  useEffect(() => {
    const fetchFunc = async () => {
      setLoading(false);
      const response = await fetch(`${BASE_URL}/press?limit=25&page=${pressCardPage}`);
      const items = await response.json();
      // const items = resJson.data.blogs;
      setPressCards(items);
      setLoading(false);
    };

    fetchFunc();
  }, [newsCardPage]);
  return (
    <div className='resourcesPage'>
      <Switch>
        <Route path={`${path}/latest-news`} render={props => (
            <LatestNews newsCards={newsCards} updateNewsCardPage={setNewsCardPage} pressCards={pressCards} updatePressCardPage={setPressCardPage} loading={loading} {...props} />
          )}/>
        <Route path={`${path}/partners`} component={PartnerPage} />
        <Route path={`${path}/company`} component={AboutEarthId} />
      </Switch>
    </div>
  );
};
 
export default AboutPage;
