import { useLocation } from "react-router-dom";

export const VerifyEmail = () => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const query = new URLSearchParams(useLocation().search);
  const verifyEmail = async () => {
    const requestOptions = {
      method: 'POST'
    };
    const response = await fetch(`${BASE_URL}/subscribers/verify/${query.get("token")}`, requestOptions);
    const status = response.status;
    // console.log(response.status);
    if (status === 200) {
      window.alert('Verified successfully. Wait to hear more from us');
    } else {
      window.alert('Not verified. Please contact us @ future@myearth.id');
    }
    window.location.replace("/"); 
  };

  return (
    verifyEmail()
  );
};