import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import '../resourcespage/resources.styles.scss';
import { VerifyEmail } from './verify-email/verify-email.component';
import './subscriber.styles.scss';

const SubscriberPage = () => {
  let { path } = useRouteMatch();
  return (
    <div className='resourcesPage'>
      <Switch>
        <Route path={`${path}/verify-email`} component={VerifyEmail} />
      </Switch>
    </div>
  );
};

export const OverlapBoxTechno = ({ img, title, children }) => {
  return (
    <div className='overlap-box-subscribe'>
      <img src={img} alt='phone-img' className='subscribe-img' />
      <div className='subscribe-content'>
        <p className='subscribe-title'>{title}</p>
        <div className='rest-para'>{children}</div>
      </div>
    </div>
  );
};

export default SubscriberPage;
