import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import './privacy.styles.scss';

const PrivacyPage = () => {

  const headerContent = `
  "EarthID", "we", "our" or "us" refers to EarthID Technology Ltd., a company incorporated in England and Wales under number 10979596 and having its registered office at International House, 12 Constance St, London E16 2DQ. EarthID is registered with the Information Commissioner’s Office as a data controller with registration number ZA557020. EarthID can also be contacted by email: <a href="mailto:dpo@myearth.id" target="_blank" rel="noreferrer">dpo@myearth.id</a>

  This Web Privacy and Cookies Policy (“Policy”) applies to our website <a href="https://myearth.id" target="_blank">https://myearth.id</a> in addition to the terms and conditions set out in our website terms and conditions of use <a href="https://myearth.id/terms-and-conditions" target="_blank">https://myearth.id/terms-and-conditions</a> (“Web Terms”). This Policy does not apply to the use of our App where personal data is collected and handled differently and which is subject to a different privacy policy <a href="https://myearth.id/app-privacy-policy" target="_blank">https://myearth.id/app-privacy-policy</a> (“App Privacy Policy”) We do not give you permission to use our website if you are prohibited from doing so under applicable laws.
  
  This Policy sets out how we will treat any personal data we obtain from you when you use our website and it is intended to help you understand how we deal with it and your queries. By providing your personal data to us, you understand that we may collect, process and use the personal data you provide in the ways described in this Policy. If you do not understand the terms of this Policy, please do not provide any personal data to us or use our website.
  
  Please note that we may need to change this Policy from time to time. We will put a notice on our website when any changes are made. Policies are available upon request.
  
  EarthID is the data controller of all “personal data” meaning any information relating to an identified or identifiable natural person, as
  
  defined in Article 4 of the General Data Protection Regulation (Regulation EU 2016/679) (GDPR) provided to us by “data subjects” (an identified or identifiable natural person) through or in connection with the use of our websites.
  `;


  return (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='' uris='' />
    <OverlapBoxPrivacy
      title='PRIVACY POLICY'
      header={headerContent}
       >
    </OverlapBoxPrivacy>

    <Getstarted/>
    <Footer />
  </div>
  )
  };

export const OverlapBoxPrivacy = ({ header, title, children }) => {
  return (
    <div className='overlap-box-privacy'>
      <div className='privacy-content'>
        <p className='privacy-main-title'>{title}</p>
        <p className="headerBlock"><div dangerouslySetInnerHTML={{ __html: header }} /></p>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">1. Personal Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. We categorise personal data as follows:<br/>
              <div className='newIdent'>
              - Identity Data includes first name, last name, company name, job title, digital identity or similar identifier;<br/>
              - Contact Data includes email address and telegram username;<br/>
              - Technical Data includes internet protocol address, login data, browser type and version, time zone setting and location, browser plug-in types and versions, you use to access our websites;<br/>
              - Profile Data includes your interests, preferences, feedback, testing and survey responses;<br/>
              - Usage Data includes information about how you use our websites; and<br/>
              - Marketing and Communications Data includes your preferences in receiving marketing from us and your communication preferences.<br/>
              </div>
              </p>
              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">2. Legal Bases for the Processing of Personal Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. Generally we do not rely on consent as a legal basis for processing your personal data other than in relation to direct marketing communications. You have the right to withdraw consent to marketing at any time by contacting us.</p>
              <p className='privacy-text-main'>2. We have set out below a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate. Note that we may process your personal data for more than one lawful ground depending on the specific purpose for
which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
<div className='newIdent'>
              <p>1. To register you as a user of our website<br/>

- We will use Identity and Contact personal data <br/>- This is to effect the performance of a contract with you</p>
              <p>2. To send email notifications of our website and alpha updates<br/>

- We will use Identity, Contact and Marketing and Communications personal data <br/>- This is to effect the performance of a contract with you; necessary to comply with a legal obligation; and necessary for our legitimate business interests (in order to manage our users’ expectations in relation to changes and updates to our website and service development and offering)</p>
              <p>3. To send email notifications of issues relating to website availability and downtime<br/>

- We will use Identity and Contact personal data <br/>- This is to effect the performance of a contract with you; necessary to comply with a legal obligation; and necessary for our legitimate business interests (in order to manage our users’ expectations in relation to service availability)</p>
              <p>4. To manage our relationship with you including dealing with your online purchases from us, your

enquiries and notifying you about changes to our Web Terms or this Policy so end users are aware that changes have been made<br/>

- We will use Identity and Contact personal data <br/>- This is to effect the performance of a contract with you; and necessary to comply with a legal obligation</p>
              <p>5. Asking you to leave a review or take a survey<br/>

- We will use Identity, Contact Profile and Marketing and Communications personal data <br/>- This is necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)</p>
<p>6. To administer and protect our business and our website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)<br/>

- We will use Identity, Contact and Technical personal data <br/>- This is necessary to comply with a legal obligation; and necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</p>
<p>7. To use data analysis to improve our website and our App, our products available for purchase online, customer relationships and experiences<br/>

- We will use all categories of personal data listed in clause 1 above <br/>- This is necessary for our legitimate interests (to define types of customers for our products and services, to keep our website and our App updated and relevant, to develop our business and to inform our marketing strategy)</p>
<p>8. To deliver relevant website content and effective advertisements and measure or understand the effectiveness of our advertising<br/>

- We will use all categories of personal data listed in clause 1 above <br/>- This is necessary for our legitimate interests (to review and to inform our website content and our marketing strategy)</p>
<p>9. To make suggestions and recommendations to you about our goods or services that may be of interest<br/>

- We will use all categories of personal data listed in clause 1 above <br/>- This is necessary for our legitimate interests (to define our products and services, and to promote our products and services)</p>
<p>10. Where we are required to share personal data to protect against fraud, or with a regulatory authority or by operation of law<br/>

- We will use all categories of personal data listed in clause 1 above <br/>- This is necessary to comply with a legal obligation</p>
<p>11. To obtain professional advice from our third party professional advisers, providing always that they are under an obligation of confidence in relation to that personal data<br/>

- We will use all categories of personal data listed in clause 1 above <br/>- Necessary for our legitimate interests (in order to take professional advice in relation to the operation of our business)</p>
<p>12. To share and/or transfer personal data within our business in order to allow for smooth operation of the website and to facilitate business operations within our own company and group of companies, or on a sale or change of control of our business<br/>

- We will use all categories of personal data listed in clause 1 above <br/>- Necessary for our legitimate interests (in order for our personnel to operate the business and to allow for the development of a group corporate structure within our business or to execute a sale or change of control of our business)</p>
</div>

              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">3. Information Collection</p>
            <div className='privacy-text'>
              <p><span className='privacy-text-main'>1. Ways in which you may be providing us with personal data include, for example,</span><br/>
              <div className='newIdent'>

- registering on our apps;<br/>

- registering to receive any updates or news we may publish;<br/>

- contacting us with an enquiry or using other contact tools available on our website or otherwise; and/or<br/>

- reporting a problem.
</div>
</p>

              <p className='privacy-text-main'>2. It is your choice to provide us with personal data.</p>
              <p className='privacy-text-main'>3. We may provide you with the option to select whether you would like to receive information from us by opting in or out. However, in some circumstances, you will not have the option to opt out. Where we need to collect personal data under the terms of our contract with you and you choose not to provide the relevant personal data, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us, but we will notify you if this is the case at the time.</p>
              <p className='privacy-text-main'>4. The information we will collect from you is limited to data concerning the use of our website, location data, weblogs and other communication data, (whether this is required for our billing purposes or otherwise) and the resources that you access, and other communication data including the resources that you access.</p>
              <p className='privacy-text-main'>5. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
              <p className='privacy-text-main'>6. We also collect, use and share Aggregated Data. Aggregated Data may be derived from your personal data but is anonymised so it is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate Technical Data to calculate the percentage of users accessing the website with a specific browser.</p>
              <p className='privacy-text-main'>7. EarthID does not sell personal data. However, we may share personal data with third parties who are our data processors to the extent necessary to deliver the updates for which a user has subscribed. Personnel employed by

these entities generally may have access to a user’s personal data for the purpose of providing the updates. However, these entities are contractually prohibited from using such information for any other purpose.</p>
<p className='privacy-text-main'>8. We do not disclose personal data to unaffiliated third parties, except as described in this privacy policy.</p>
<p className='privacy-text-main'>9. If all or part of our business is sold, or we or our affiliates undertake a corporate reorganisation, we may transfer your personal data to the new owner of the business, or to the successor entity in the reorganisation.</p>

              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">4. Third Party Use Of Personal Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. EarthID does not sell personal data. However, we may share personal data with third parties who are our data processors to the extent necessary to deliver the services and information for which a user has subscribed. Personnel employed by these entities generally may have access to a user’s personal data for the purpose of providing the updates. However, these entities are contractually prohibited from using such information for any other purpose.</p>
              <p className='privacy-text-main'>2. We do not disclose personal data to unaffiliated third parties, except as described in this privacy policy.</p>
              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">5. IP Addresses and Cookies</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. Like many other websites, we use &ldquo;cookies&ldquo; to help us gather and store information about visitors to our website.</p>
              <p className='privacy-text-main'>2. We may collect information about your computer, including your IP address, operating system and browser type, for system administration and to report aggregate information for use in our business development. This is statistical data about our website users&lsquo; browsing actions and patterns, and does not identify you as an individual and is therefore not personal data.</p>
              <p className='privacy-text-main'>3. A  &ldquo;cookie&ldquo; is a small text file which is downloaded on to your computer&lsquo;s hard disk when you access certain websites. Cookies allow the website to recognise your

computer. A cookie can identify the pages that are being viewed and this can assist us to select the pages that the website user sees.</p>
              <p className='privacy-text-main'>4.  &ldquo;Session&ldquo; cookies only exist whilst the website user is online on a particular occasion. These are temporary cookies that aid your journey around the site and remember the preferences you have selected during your  &ldquo;session&ldquo;.</p>
              <p className='privacy-text-main'>5. We do not store sensitive personal data in  &ldquo;persistent&ldquo; cookies, and cookies in themselves, do not contain enough information to identify you. You will only become personally identifiable in relation to your browsing habits, after you have formally provided us with your personal data as per the terms of this Policy. Once you have formally provided us with your personal data, you will become personally identifiable in relation to your browsing habits from when the cookie was first downloaded onto your computer.</p>
              <p className='privacy-text-main'>6. In addition to using cookies, we might also use GIF&lsquo;s and other web tools, such as Google Analytics, to collect information about your browsing activities whilst on our site. In this respect the information that is provided is similar to the information supplied by cookies, and we use it for the same purposes.</p>
              <p className='privacy-text-main'>7. Any information that we acquire about you using cookies, GIF&lsquo;s, or other web tools is subject to the same restrictions and conditions as any other information we collect about you, as outlined in this Policy.</p>
              <p className='privacy-text-main'>8. We have links to other websites on our website and the third party websites may also use cookies, GIF&lsquo;s, or other web tools that are set by other people. This will only affect you if you click through a link on our website to visit a third party website. We do not have access to or control over any information that might be collected in

this way by third party websites, and this will be subject to the terms and conditions of that third party website.</p>
              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">6. Cookies Used on Our Website</p>
            <div className='privacy-text'>
              <p>1. Cookies set by Google Analytics:<br/>
              <div className='newIdent'>
Cookie name <br/>- Google analytics sets the following cookies: _ga, _gid, _gat, AMP-TOKEN, _gac_, _utma, _utmb, _utmc, _utmz, or others within the range _utma to _utmz.

Purpose <br/>- Google Analytics is a web analytics service provided by Google, inc. by default, Google Analytics sets four performance cookies in order to evaluate your use of the site, including number of visits, duration of browsing and referring sites, and compiles reports for us on activity on the site. all information these cookies collect is aggregated and therefore anonymous. Google stores the information collected by the cookie on servers in the united states. google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google&lsquo;s behalf. google state that they will not associate your IP address with any other data held by google. For more information about the way in which google analytics uses these cookies please visit the following link <br/>- https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage </div></p>
              
              <p>2. If you wish to know all the cookies we have set and how to delete or manage them, please refer to www.allaboutcookies.org</p>
              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">7. How to Enable/Disable Cookies</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. You have the ability to accept or decline cookies by modifying the settings in your browser or by not clicking the &ldquo;dismiss&ldquo; button on the box that appears on the first page you see on our website. For example, in Internet Explorer, you can go to Tools and Internet Options, where there is the option to change your settings to disable cookies. However, you may not be able to use all the interactive features of our site if the cookies are disabled.</p>
              <p className='privacy-text-main'>2. You also have the ability to delete cookies that have been installed in the cookie folder of your browser and should search for &ldquo;cookies&ldquo; in your &ldquo;Help&ldquo; function for information on where to find your cookie folder.</p>
              <p className='privacy-text-main'>3. You should note that some websites store your member name, password, or other information about you in a cookie. If you delete that cookie, you might need to re-enter your personal information again the next time you visit the website.</p>
              <p className='privacy-text-main'>4. To delete only the cookies set by our website in Internet Explorer, you must do the following:- <br/>
              <div className='newIdent'>
              - In Internet Explorer, click the Tools button, and then click Internet Options. <br/>- On the General tab, under Browsing history, click Settings. <br/>- Click the View files button. <br/>- Click the Name column heading to sort all the files alphabetically, and then scroll down until you see files that begin with the prefix Cookie. All cookies will have that prefix, and they usually contain the name of the website that created the cookie. <br/>- Right-click the cookie you want to delete, click Delete, and then click Yes. <br/>- Close the window that contains the list of files, and then click OK twice to return to Internet Explorer.
              </div>
              </p>
              <p className='privacy-text-main'>5. To delete only the cookies set by our website, for example in Firefox, you must do the following:-<br/>
              <div className='newIdent'>
- At the top of the Firefox window, click on the Firefox button and then click Options <br/>- Select the Privacy panel. <br/>- Click the View files button. <br/>- Set Firefox will: to Use custom settings for history. <br/>- Click Show Cookies.... The Cookies window will appear. <br/>- In the Search field, type the name of the site whose cookies you want to remove. The cookies that match your search will be displayed. <br/>- Select the cookie(s) in the list to remove and click Remove Cookie <br/>- Select the first cookie and press Shift + End to select all the cookies in the list. <br/>- Click Close to close the Cookies window.
</div>
</p>
              <p className='privacy-text-main'>6. In order to delete the Session cookie, you may need to contact your system administrator for assistance in re-configuring your server.</p>
              <p className='privacy-text-main'>7. Unless you have adjusted your browser settings so that it will refuse cookies, our website will issue cookies when you log on to our website.</p>
<p>8. To prevent Google Analytics cookies being set, you may install the Google Analytics Opt-Out Browser Add-On by clicking on this link <br/>
<div className='newIdent'>
- http://tools.google.com/dlpage/gaoptout and following the instructions.
</div>
</p>
              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">8. Change of Purpose</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>
               </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">9. Protection of Information</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. We have established and maintain internal policies and procedures to encrypt and protect personal data from

unauthorized use or disclosure. For example, our security methods may include device safeguards, secured files and buildings and oversight of our third-party service provides or similar entities. That said, no data transmission or storage system can be guaranteed to be 100% secure and, thus, we do not guarantee that these safeguards will prevent the interception of personal data transmitted via the internet or that personal data stored in our systems, or that is otherwise in our care, will be safe from unauthorized use or some other form of intrusion by third-parties.</p>
              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">10. Third Party Websites</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. Our websites may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements and we do not make any representations or warranties regarding the content or accuracy of materials on such third party sites. Such sites may have Terms of Use, Privacy Policies, or security practices different from EarthID and we do not endorse or recommend, and hereby disclaim any liability for, any information or products offered at such sites. When you leave our website, we encourage you to read the privacy notice of every site you visit.</p>
              </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">11. Transfers of Personal Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. Personal data of individuals located in the European Economic Area (“EEA”) may be transferred to a jurisdiction outside the EEA because EarthID technology is based on a decentralised system and distributed ledger and we use service providers with global systems in hosting and in executing identity verification and transactions via the App. No other third parties will receive any personal data. Your personal data will be held

by us on the same no knowledge basis wherever it is stored.</p>
              <p>2. If we transfer any personal data, which you may instruct us to do by operation of the website, we will only transfer data to service providers in countries that have been deemed to provide an adequate level of protection by the European Commission. If we use service providers, this will be on contractual terms which give personal data the same protection as it has in the EEA. If we use service providers in the USA, we will only use service providers that are part of the Privacy Shield which requires the service providers to give personal data similar protection as it has in the EEA. If you require further information on where your data may be transferred to please get in touch with us.</p>
               </div>
          </div>
        </div><div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">12. Retention of Personal Data Information</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements which we expect to be for no more than for seven years after they cease being users in line with taxation limitation period purposes.</p>
              <p className='privacy-text-main'>2. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
              <p className='privacy-text-main'>3. We may use Aggregated Data for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
               </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">13. Individual Rights</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1. Under certain circumstances, you may have rights under data protection law in relation to your personal data as

follows especially if you are an EU citizen: <br/>
<div className='newIdent'>
- Request access to your personal data <br/>- Request correction of your personal data <br/>- Request erasure of your personal data <br/>- Object to processing of your personal data <br/>- Request restriction of processing your personal data <br/>- Request transfer of your personal data <br/>- Right to withdraw consent
</div>
</p>
              <p className='privacy-text-main'>2. These rights are all subject to some limitations as provided for by applicable law. If you wish to exercise any of the rights set out above, please contact us at <a href='mailto:privacy@myearth.id' target="_blank" rel="noreferrer">privacy@myearth.id</a>.</p>
              <p className='privacy-text-main'>3. In line with GDPR you will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
              <p className='privacy-text-main'>4. We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
              <p className='privacy-text-main'>5. We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
              <p className='privacy-text-main'>6. Individuals located in the EU have the right to lodge a complaint about the processing of their personal data with the supervisory authority of the data controller, which is the Information Commission’s Office.</p>
              <p className='privacy-text-main'>6. Marketing and Communications Data includes your preferences in receiving marketing from us and your communication preferences.</p>
              </div>
          </div>
        </div>
       

        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">14. Treating Users Properly</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>At EarthID, we are committed to maintaining the highest standards of user interaction and service delivery, ensuring that all users are treated fairly and are fully informed about the products and services they are using.</p>
              <p className='privacy-text-main'>1. Informing User: <br/>Product and Service Information: We provide clear, accessible information about the features, benefits, and limitations of our digital identity products and services. This information is readily available on our website and our detailed user brochures. 
              <br/>
Transparency: We are transparent about how we use and store user data, the technology employed, and the direct benefits to users. A clear statement of our business model, including monetization strategies, is separately available for easy access and understanding. </p>
              <p className='privacy-text-main'>2. User Agreement and Consent: <br/>

At Account Creation: Consent is obtained transparently at the point of account creation, detailing what the service entails and any data implications. 
<br/>
At Each Interaction: We also seek consent at each significant interaction where data may be shared or disclosed, ensuring continuous user awareness and compliance. </p>
              <p className='privacy-text-main'>3. Handling Complaints and Disputes: <br/>

EarthID has a formal process for addressing and resolving user complaints and disputes promptly and fairly. Please reach out to <a href='mailto:support@myearth.id' target="_blank" rel="noreferrer">support@myearth.id</a>.</p>
              <p className='privacy-text-main'>4. Delegated Authority:
<br/>
We collaborate with our clients to establish clear guidelines and verify the delegated authority in situations where a user acts on behalf of another person or entity, adhering to legal and regulatory requirements.</p>
              <p className='privacy-text-main'>5. Accessibility:
<br/>
EarthID complies with all relevant accessibility standards, including the Web Content Accessibility Guidelines (WCAG) and the standards set by the European Telecommunications Standards Institute (ETSI). 
<br/>
We provide alternative access methods to ensure that our services are accessible to all users, regardless of any disability or technology limitation. </p>
              <p className='privacy-text-main'>6. Inclusion:
<br/>
An annual monitoring report is published detailing our efforts to ensure inclusivity and accessibility in our services. 
<br/>
We continuously develop strategies to improve the inclusiveness and accessibility of our services based on user feedback and annual audit findings. </p>
              <p className='privacy-text-main'>7. Prohibited Conduct:
<br/>
We adhere to the highest ethical standards, ensuring that all activities are legal and uphold the integrity of the digital identity framework.</p>
              
              </div>
          </div>
        </div>


        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">15. Reusable Identity</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>EarthID offers digital ID wallets which enable users to reuse their verified identities and attributes. These wallets are provided to end users, either by white-labeling EarthID’s technology or by integrating them into existing apps of our clients.</p>
              <p className='privacy-text-main'>1. User Agreement:
<br/>
At Account Opening: Explicit consent is obtained from users covering terms of use, data sharing, and privacy policies. 
<br/>
At Each Interaction: Consent is obtained whenever personal data is shared or disclosed during the use of our services. </p>
              <p className='privacy-text-main'>2. Account Management:
<br/>
Account Suspension: If suspicious activity is detected, the account is immediately suspended to prevent unauthorized access, with prompt notification to the affected user. 
<br/>
Account Recovery and Replacement: We ensure that account recovery and replacement are handled with rigorous identity verification to safeguard user accounts. </p>
               </div>
          </div>
        </div>


        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">16. Responsible Age Verification</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>EarthID firmly believes in the importance of responsible age verification to protect minors and comply with legal requirements. We are fully committed to safeguarding children and young people by ensuring that age-restricted goods and services are accessed only by those who meet the legal age requirements.</p>
              <p className='privacy-text-main'>1. Policy Statement:
<br/>
Our policy underscores our commitment to upholding the highest standards of age verification, guided by our core values of privacy, security, and user empowerment. We use innovative technology and adhere to global standards to ensure a trustworthy and respectful verification process.</p>
              <p className='privacy-text-main'>2. Verification Process:
<br/>
EarthID performs age verification at the point of account creation or when a user first accesses age-restricted services. This process is designed to be both effective and minimally invasive, respecting the privacy and dignity of all users.</p>
              <p className='privacy-text-main'>3. No Rechecks:
<br/>
Once a user’s age has been verified, EarthID does not perform rechecks. However, relying parties may, at their discretion, implement additional verification processes or rechecks to ensure ongoing compliance with their specific regulatory requirements or internal policies.</p>
              <p className='privacy-text-main'>4. Technology and Compliance:
<br/>
We are committed to using advanced technologies that enhance the accuracy and reliability of age verification while ensuring compliance with legal age restrictions and protecting the rights of all users.</p>
              <p className='privacy-text-main'>5. User Control and Consent:
<br/>
Users have control over their information and can manage their consent regarding the use of their data for age verification purposes. This includes understanding how their data is used and the ability to withdraw consent where applicable.</p>
              <p className='privacy-text-main'>6. Security Measures:
<br/>
EarthID takes robust security measures to protect personal information during the age verification process and ensures that all data handling practices comply with GDPR and other relevant privacy laws.</p>
              </div>
          </div>
        </div>


        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">17. Business Model:</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>EarthID operates on a B2B model, providing services to businesses rather than charging individual users. Our clients, which include banks and other service providers, are charged for integrating EarthID’s technology to offer secure digital identity solutions to their customers.</p>
               </div>
          </div>
        </div>
      
       
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">Effective as of 5 October 2023</p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-privacy'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
       
      </div>
    </div>
  );
}; 
export default PrivacyPage;