import React from 'react';
import './ssi-page.styles.scss';
import { Footer } from '../../components/essentials/footer/footer.component';
import {Getstarted} from '../../components/essentials/get-started/getstarted.component';
import { OverlapBox } from '../../components/essentials/overlap-box/overlap-box.component';
import { NavBar } from '../../components/essentials/navbar/navbar.component';
import ssi from '../../assest/SSI.png';

const SSIPage = () => {
  return (
    <div className='ssi-platform'>
      <NavBar />
      <HeaderHalf title='SSI' uris='SSI' />
      <OverlapBox
        title=''
        className='overlap-box'>
        <div>
          <div>
            <div className="ssi-block">
              <p className="ssi-sub-title">Creating An Identity Ecosystem That Puts The User In Control Of Their Data</p>
              <p className='ssi-text'>
                Identity playing an important role in socio-economic inclusion has made customers look for more robust identity solutions than ever before. But, the existing monolithic centralized identity platforms have failed to put users in charge of their personal data while authenticating and verifying their credentials. And, this has developed a huge mistrust in the minds of the customers when trying to adopt <strong>identity verification solutions</strong>.</p>
              <p className='ssi-text'>
                At EarthID, we understand the importance of Self Sovereignty. We acknowledge how crucial it is for a user to have unlimited control over their identity, thereby restricting the third party from taking their data away without the user’s consent.
              </p>
              <p className='ssi-text'>
                The essence of Self Sovereign Identity lies in the concept of individuals or organizations having sole ownership of their digital identities, having full control over how and when their personal data is being shared and used.
              </p>
              <div className='image-ssi-container'>
                <img src={ssi} className='img-for-ssi' alt='ssi-img' />
              </div>
            </div>
            <div className="ssi-block">
              <p className='ssi-text'>
                To provide the user with the freedom to share and take control over their data, Self Sovereign Identity uses a set of technologies build upon core concepts of distributed computing, identity management, Distributed Ledger Technology, as well as cryptography.
              </p>
              <p className='ssi-text'>
                At a core level, SSI enables users to have verifiable credentials to move the utility and portability of physical identity documents into the digital wallets of the user. Thereby making them take full control over their personal identity data.
              </p>
              <ul className='ssi-list'>
                <li>Issuer generates and delivers attested credentials to the holder upon request, which is now stored with the holder (Issuer can revoke the credentials when expired).</li>
                <li>Verifier requests the holder/user for information required for the assessment process.</li>
                <li>Holder is notified and asked for permission when the verifier requests to access confidential information. The Holder shares information with the verifier using a secure SSI-based Digital Wallet.</li>
                <li>Verifier validates the authenticity of the credentials presented by the holder and validates using cryptography.</li>
              </ul>
            </div>
          </div>
          <div className="ssi-block">
            <p className='ssi-sub-title small-text'>
              EarthID lets you explore the benefits of a decentralized data model with user-controlled privacy.
            </p>
          </div>
        </div>
      </OverlapBox>
      <Getstarted/>
      <Footer />
    </div>
  );
};

//odfgh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-ssi'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Home / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};


export default SSIPage;