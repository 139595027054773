import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import phoneImg from '../../../assest/25 - Age Verification/Transparent Cropped phone.png';
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import photoOnPhone from '../../../assest/25 - Age Verification/How it works white.png'
import benefits from '../../../assest/25 - Age Verification/Benefits (2).png'
import './age-verification.scss';
//import { OverlapBoxTechno } from '../../technopage/techno.component';
import {  OverlapBoxFeature } from '../features-page/features-page.component';

import tobacco from '../../../assest/25 - Age Verification/icons/tobacco.png';
import gamble from '../../../assest/25 - Age Verification/icons/gaming.png';
import alcohol from '../../../assest/25 - Age Verification/icons/alcohol.png';
import cbd from '../../../assest/25 - Age Verification/icons/cbd.png';
import content from '../../../assest/25 - Age Verification/icons/content.png';
import pharma from '../../../assest/25 - Age Verification/icons/pharma.png';
import diy from '../../../assest/25 - Age Verification/icons/diy.png';
import weapon from '../../../assest/25 - Age Verification/icons/weapons.png';

import complaint from '../../../assest/25 - Age Verification/icons/compliant.png';
import privacy from '../../../assest/25 - Age Verification/icons/privacy preserving.png';
import seamless from '../../../assest/25 - Age Verification/icons/seamless integration.png';
import global from '../../../assest/25 - Age Verification/global coverage.png'
import dataAnalytics from '../../../assest/25 - Age Verification/9624 man.png'

//import {  MediumCard } from '../platformpage/platform.component';
//import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';

export const AgeVerification = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Age Verification' uris='Age Verification' />
    <OverlapBoxTechno
      img={phoneImg}
      title='A Privacy Preserving Approach To Age Verification.'
    >
      <p>
      You don&apos;t need to know someone&apos;s name or even their date of birth to verify their age. 
      Our solution allows users to confirm they meet the age requirements for your service without revealing any personal information, promoting privacy for everyone.
      </p>
      <p>
      Effortlessly confirm the age of your customers with our comprehensive solution, designed to detect fake IDs and prevent advanced digital fraud methods like spoofing and deepfakes. 
      Our system ensures compliance with legal age requirements, protecting your business from underage access.
      </p>
      <p>
      Our state-of-the-art age verification technology, which employs privacy-preserving and anonymized Zero Knowledge Proofs (ZK Proofs), enables users to verify their eligibility without sharing their name or date of birth. This approach enhances privacy and builds trust. 
      Our solution is perfect for both in-person and online age verification, ensuring compliance and boosting user confidence.
      </p>
    </OverlapBoxTechno>
    <OverlapBoxFeature title='How Our Solution Works'>
        <MoreBox />
      </OverlapBoxFeature>
      <OverlapBoxIndustry title='Industry Use Cases' className=' overlap-box-industry'>
        <p className='industries-text'>
        Age verification is crucial for businesses that provide age-restricted products and services. 
        Whether you are offering online gaming, adult content, alcohol sales, or any other service that requires age restrictions, verifying the age of your users is a legal necessity. However, traditional age verification methods often require sensitive personal information, which can be a deterrent for users concerned about their privacy. 
        Secure your transactions responsibly and confidently with EarthID&apos;s age verification solutions.
        </p>
        <div className='cards-div-industries'>
          <LargeCard
            img={tobacco}
            title='Tobacco Products'
            desc="It&apos;s illegal to sell tobacco to anyone under 18, so its essential vendors use age verification solution to ensure legal compliance while delivering a positive customer experience."
          />
          <LargeCard
            img={gamble}
            title='Gaming & Gambling'
            desc="As gaming, betting and gambling platforms evolve into social platforms, players can meet up, watch live streams and make purchases. To make sure their experience is right for their age, online age verification is now a necessity."
          />
          <LargeCard
            img={alcohol}
            title='Alcohol'
            desc="Age verification measures are vital to the smooth running of your business, and to ensure that you meet the various regulations around the sale of alcohol. This doesn&apos;t just protect you as a seller, but your consumers too."
          />
          <LargeCard
            img={cbd}
            title='CBD Products'
            desc="Retailers face fines, loss of license, or other penalties for selling CBD to underage customers. These laws are enforced to maintain a safe environment for everyone involved."
          />
          <LargeCard
            img={content}
            title='Content'
            desc="For distributing adult content or selling adult products online, implementing age verification measures is crucial to comply with the diverse regulations and codes of practice that governments worldwide impose to prevent access by underage users."
          />
          <LargeCard
            img={pharma}
            title="Pharma"
            desc="Comply with local laws and regulations while selling pharmaceuticals online safely. As more consumers turn to the internet for their pharmaceutical needs, EarthID offers robust online retail age verification capabilities."
          />
          <LargeCard
            img={diy}
            title='Home Improvement/DIY'
            desc='Deliver a great checkout experience, while complying with local law and legislation. If you sell potentially hazardous DIY equipment online, you could be fined for not following age verification rules.'
          />
          <LargeCard
            img={weapon}
            title='Weapons'
            desc="Ensure compliance and protect customers when selling weapons (firearms/knives) online. Navigating the complexities of selling restricted-use goods demands staying current with frequent changes in laws and regulations."
          />          
        </div>
      </OverlapBoxIndustry>
      <OverlapBoxFeature  title='Solution Benefits'>
        <EarthidBox />
      </OverlapBoxFeature>
      <OverlapBoxFeature title='Establishing Trust With Our Privacy First Age Verification Solution'>
      <div className='platform-content'>
          <p className='cards-div-title'>
          By integrating our privacy-first age verification solution, you can confidently comply with age restrictions while maintaining the highest standards of user privacy.
        </p>
          <div className='cards-div-platform'>
            <MediumCard
              icon={complaint}
              title='Compliant'
              desc='Our solution aligns with the age and developmental categories introduced by the ICO. We are certified by ACCS as being PAS 1296 compliant, according to the Code of Practice for Online Age Verification Service Providers in the UK. Additionally, we are GDPR and ISO 27001 certified.'
            />
            <MediumCard
              icon={privacy}
              title='Privacy Preserving'
              desc='We believe privacy is paramount. Our innovative solution ensures that users can confirm they meet your age requirements without revealing their name or even their date of birth. This approach protects personal information and enhances both user trust and compliance with your services.'
            />
            <MediumCard
              icon={seamless}
              title='Seamless Integration'
              desc='EarthID is designed for optimal efficiency, offering a smooth, seamless integration into your website or app. Utilizing our advanced SDK and APIs, our age verification solution enhances the customer journey by ensuring uninterrupted user engagement without any distractions.'
            />
          </div>
        </div>
      </OverlapBoxFeature>
      <SectionRight
        title="Global Coverage"
        title1="11K+ Documents Supported"
        content1= "EarthID supports over 11,500 identity documents from more than 230 countries and territories. Our extensive global reach allows you to verify your user's age from anywhere in the world."
        
        title2="45+ Languages and Dialects Supported"
       content2= "We have tailored our end-user flows to cater to the world's largest markets, providing support for 45+ languages and dialects. By offering a wide range of languages, we ensure that we can serve a diverse customer base. Our commitment to expanding our language offerings helps us meet the strategic goals of our partners."
        imgPath={global}
      ></SectionRight>
      <SectionLeft
        title="Advanced Security Measures"
        title1="Device and Network Analytics"
        content1= "As part of our comprehensive fraud prevention measures, EarthID analyzes the device and network used during a verification session, assigning each a unique fingerprint. This, along with hundreds of other parameters and checks for inconsistencies in personal data on the ID, ensures a robust verification process before delivering results."
        
        title2="Liveness Detection"
       content2= "EarthID&apos;s advanced liveness detection technology determines if a session or user is real or a spoof attempt, all without requiring extra steps from your customers. This seamless process enhances security while maintaining a smooth user experience."
        imgPath={dataAnalytics}
      ></SectionLeft>
    <Getstarted/>
    <Footer />
  </div>
);

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-distributeav'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};

const MoreBox = props => {
    return (
      <div className='kys-div'>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Secure ID Upload'
            desc='Customers upload their government-issued ID into their secure personal digital wallet.'
          />
          <KYSCard icon={kysBulletPoint} title='Rigorous Checks'
            desc="EarthID rigorously checks the document for authenticity, validity, and any tampering to ensure the highest security standards."
          />
          <KYSCard icon={kysBulletPoint} title='Selfie Comparison and Liveness Test'
            desc='To confirm the ID belongs to the customer, EarthID performs a selfie comparison and a liveness test to prevent impersonation.'
          />
        </div>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Secure Storage'
            desc='After verification, the ID is securely stored in the customer&apos;s personal digital wallet, along with their date of birth.'
          />
          <KYSCard icon={kysBulletPoint} title='Privacy-Centric Verification'
            desc='EarthID simply indicates "Yes" or "No" to confirm if a customer meets the age requirements, without disclosing their date of birth or any personal information.'
          />
          <KYSCard icon={kysBulletPoint} title='Effortless Subsequent Transactions'
            desc='After the initial setup, all subsequent transactions are fast and seamless, enhancing the customer experience with minimal friction.'
          />
        </div>
        <div className='kys-platform-img'> <img src={photoOnPhone} className='kys-platform-imagee' alt='MoreCardIcon' /></div>
  
      </div>
  
  
    );
  };

  const OverlapBoxIndustry = props => {
    return (
      <div className='overlap-boxx'>
        <p className='box-title'>{props.title}</p>
        {props.children}
      </div>
    );
  };

  const LargeCard = ({ img, desc, title }) => {
    return (
      <div className='large-card'>
        <img src={img} className='large-card-img' alt='largeCardImg' />
        <p className='large-card-title'>{title}</p>
        <p className='large-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    );
  };

  const EarthidBox = props => {
    return (
      <div className='kys-div'>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Effortless Verification'
            desc='Customers can seamlessly prove their age through cryptographic proof, eliminating the need for manual checks.'
          />
          <KYSCard icon={kysBulletPoint} title='Swift Process'
            desc='Complete the entire age verification process in just a few seconds, removing the hassle of handling physical documents.'
          />
  
          <KYSCard icon={kysBulletPoint} title='Enhanced Privacy'
            desc="EarthID uses Zero Knowledge Proofs (ZK Proofs) to verify age without storing personal data, significantly enhancing user privacy."
          />
           <KYSCard icon={kysBulletPoint} title='Regulatory Compliance'
            desc='Our solution ensures compliance with regulations such as GDPR by minimizing data collection and guaranteeing data accuracy.'
          />
        </div>
        <div className='kys-platform-content'>
         
  
          <KYSCard icon={kysBulletPoint} title='Seamless Experience'
            desc='Unlike age estimation methods that require multiple interactions, EarthID offers a streamlined and frictionless user experience.'
          />
          <KYSCard icon={kysBulletPoint} title='Precise Determination'
            desc='Leveraging verified data, EarthID offers precise age verification, overcoming inaccuracies common with appearance-based estimation.'
          />
          <KYSCard icon={kysBulletPoint} title='Unbiased Results'
            desc='EarthID delivers consistent and unbiased age verification across all demographics, addressing the demographic biases present in other age estimation solutions.'
          />
          <KYSCard icon={kysBulletPoint} title='Dependable Enforcement'
            desc='Our solution is highly reliable for enforcing age restrictions in regulated industries, ensuring compliance and consistency.'
          />
        </div>
        <div className='kys-platform-img'> <img src={benefits} className='kys-platform-imagee' alt='kysCardIcon' /></div>
  
      </div>
  
  
    );
  };


  const SectionRight = props => {
    return (
      <div className='feature-box-right'>
        <p className='title-feature'>{props.title}</p>
        <div className="display-flex">
          <div className='feature-box-content'>
            <div className='overallcontent'>
            <p className='title1-feature'>{props.title1}</p>
          <p className='content1-feature'>{props.content1}</p>
          <p className='title1-feature'>{props.title2}</p>
          <p className='content1-feature'>{props.content2}</p>
            </div>
          
          </div>
          <div className='feature-box-image'>
            <img src={props.imgPath} />
  
          </div>
        </div>
      </div>
    );
  };

  const SectionLeft = props => {
    return (
      <div className='feature-box-left'>
        <p className='title-feature'>{props.title}</p>
        <div className="display-flex">
        <div className='feature-box-image'>
            <img src={props.imgPath} />
  
          </div>
          <div className='feature-box-content'>
            <div className='overallcontent'>
            <p className='title1-feature'>{props.title1}</p>
          <p className='content1-feature'>{props.content1}</p>
          <p className='title1-feature'>{props.title2}</p>
          <p className='content1-feature'>{props.content2}</p>
            </div>
          
          </div>
          
        </div>
      </div>
    );
  };

 const KYSCard = ({ icon, desc, title }) => {
    return (
      <div className='kpl-card'>
        <div className="key-icon-col"><img src={icon} className='kys-card-icon' alt='kysCardIcon' /></div>
        <div className="key-text-col">
          <p className='kys-card-title'>{title}</p>
          <p className='kpl-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
        </div>
      </div>
    );
  };

 const OverlapBoxTechno = ({ img, title, children }) => {
    return (
      <div className='overlap-box-technoo'>
        <img src={img} alt='phone-img' className='techno-img' />
        <div className='techno-content'>
          <p className='techno-title'>{title}</p>
          <div className='rest-para'>{children}</div>
        </div>
      </div>
    );
  };


  const MediumCard = ({ icon, desc, title }) => {
    return (
      <div className='medium-card'>
        <img src={icon} className='medium-card-icon-platform' alt='mediumCardIcon' />
        <p className='medium-card-title'>{title}</p>
        <p className='medium-card-desc-platform'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    );
  };