import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import './privacy.styles.scss';

const MobileAppPrivacy = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='' uris='' />
    <OverlapBoxPrivacy
      title='MOBILE APPLICATION END-USER LICENCE'
      header='PLEASE READ THESE LICENCE TERMS CAREFULLY

      BY CLICKING ON THE “ACCEPT” BUTTON BELOW YOU AGREE TO THE TERMS AND CONDITIONS OF THIS LICENCE (“Licence”) WHICH WILL BIND YOU.
      
      IF YOU DO NOT AGREE, CLICK ON THE “REJECT” BUTTON BELOW.'
    >

    </OverlapBoxPrivacy>

    <Getstarted/>
    <Footer />
  </div>
);

export const OverlapBoxPrivacy = ({ header, title, children }) => {
  return (
    <div className='overlap-box-privacy'>
      <div className='privacy-content'>
        <p className='privacy-main-title'>{title}</p>
        <p className="headerBlock">{header}</p>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">1. Who We are and What this Licence Does</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1.1 We, EarthID Technology Ltd., a company incorporated in England and Wales under number 10979596 and having its registered office at International House, 12 Constance St, London E16 2DQ, license you to use the following in accordance with the terms and conditions set out below:</p>
              <p>
              <div className='newIdent'>
                1.1.1 EarthID mobile application software, (&ldquo;App&ldquo;) and any updates or supplements to it;<br/>

1.1.2 the related online documentation (“Documentation”); and<br/>

1.1.3 the ID and Payment Platform you connect to via the App and the content we provide to you through it (&ldquo;Service&ldquo;).
</div>
</p>
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">2. Your Privacy</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>2.1 Because we do not collect and process your personal contact data, we have no means to contact you personally. We use push notifications to keep you up to date via the App. We may send you service notifications and transactional notifications to tell you about the operation of the App and any changes to the terms and conditions of this Licence, the App, the Documentation and the Service.</p>
              <p className='privacy-text-main'>2.2 Please refer to our App Privacy Policy <br/>
              <div className='newIdent'>
              - <a href='https://myearth.id/app-privacy-policy' rel="noreferrer" target='_blank'>https://myearth.id/app-privacy-policy</a> <br/>- which sets out the limited ways in which we process personal data you supply or that we collect through your use of the App and the Services.
              </div>
              </p>
            <p className='privacy-text-main'>2.3 You can email us at <a href="mailto:terms@myearth.id" target="_blank" rel="noreferrer">terms@myearth.id</a> if you wish to contact us.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">3. Operating System Requirements</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>3.1 Currently the App requires a device with the minimum operating system of Android 7.1 (API 16) or iOS 10.0 with biometrics enabled. Please see clause 6.2 in relation to changes in operating system requirements.</p>
              
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">4. Contact and Support</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>4.1 If you have any questions about these Web Terms or our website or if you want to learn more about the App or have any problems using them, you can email us at <a href="mailto:terms@myearth.id" target="_blank" rel="noreferrer">terms@myearth.id</a></p>
              
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">5. How You May Use the App and Security</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>5.1 You must be 18 or over to accept these terms and download the App</p>
              <p className='privacy-text-main'>5.2 You must be in a jurisdiction where you are permitted to use the App under applicable laws. You may not download or use the App if you are prohibited from doing so under applicable laws.</p>
            <p><span className='privacy-text-main'>5.3 In return for your agreeing to comply with the terms of this Licence you may:</span><br/>
            <div className='newIdent'>
5.3.1 download a copy of the App and view, use and display the App and the Service on any number of devices providing always that this is for your personal use only; you may not share the App and the Service irrespective of any AppMarketplace rules on family sharing; we make this a condition of your licence in order to protect you from

possible risks of transactions being made through EarthID by other members of your family without your knowledge, because you are responsible for all your EarthID transactions;<br/>

5.3.2 use any Documentation to support your permitted use of the App and the Service;<br/>

5.3.3 provided you comply with the Licence Restrictions (below), make copies of the App and the Documentation for back-up purposes; and<br/>

5.3.4 receive and use any free supplementary software code or update of the App incorporating “patches” and corrections of errors as we may provide to you.
</div>
</p>
            <p className='privacy-text-main'>5.4 We are granting you personally a licence to use the App and the Service. You may not otherwise transfer the App or the Service to someone else, whether for money, or anything else or for free. You may not download the App onto any device not owned by you. If you transfer any device on which the App is installed, you must remove the App from it before you transfer the device.</p>
            <p className='privacy-text-main'>5.5 You must take all reasonable steps to keep your security details safe (including any private keys, PIN’s and mnemonics) and to create your own back up of private keys and mnemonics. You have sole responsibility for the private key and mnemonics required to access.</p>
            <p className='privacy-text-main'>5.6 You must not leave your device unattended whilst you are logged in to EarthID.</p>
            <p className='privacy-text-main'>5.7 EarthID will never ask you for your security details.</p>
            <p className='privacy-text-main'>5.8 EarthID does not have access to and cannot ever retrieve your security details including your private keys and mnemonics whether

in relation to the App or in relation to third party sites including any third party wallets, payment processors or digital asset exchange company and so you must keep your security details safe and have back ups in relation to these platforms because their loss will result in you losing access to your assets associated with the relevant private key and mnemonic them irretrievably.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">6. Updates and Changes</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>6.1 From time to time we may automatically update the App and change the Documentation and the Service to improve performance, enhance functionality, reflect changes to the operating system or address security issues. Alternatively we may ask you to update the App for these reasons. If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the App and the Services.</p>
              <p className='privacy-text-main'>6.2 You are responsible for ensuring that your device is capable of operating the App, which we will update from time to time. Whilst we expect that the App will always work with the current version of the operating system (as it may be updated from time to time) and the immediately preceding version and substantially match the description of it provided to you when you downloaded it, we do not expect to maintain and support the App in relation to older versions. We therefore give no warranty in relation to maintaining the service level in relation to the immediately preceding version of the operating system or in any other respect and we may modify the App accordingly.</p>
            <p className='privacy-text-main'>6.3 There may be times when the App is unavailable for you to use due to maintenance or upgrades or other servicing. We would expect to send a push notification in advance of planned downtime but we may not always be able to do so and because of the nature of push notifications you may not receive the communication before planned downtime.</p>
          
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">7. Technical Data About Device(s)</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>7.1 Currently, in relation to devices, we collect, using our licensed push notification software, the unique universal identifier code from your device which we then use to send all push notifications to all our users.</p>
              <p className='privacy-text-main'>7.2 We will tell you by push notification if we are going to do anything differently in relation to technical information about the devices on which you use the App and related software, hardware and peripherals to improve the App, our products and to provide our Service to you, in addition to the action in clause 7.1</p>
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">8. Location Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>8.1 Currently location services are not part of our technology and we do not collect location data sent from your devices.</p>
              <p className='privacy-text-main'>8.2 If that changes then we will update this Licence and notify you by push notification.</p>
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">9. We are Not Responsible for Other Websites You Access</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>9.1 The App or any Service may contain links to third party websites. Such sites are not under our control, and we are not responsible for and have not checked and approved their content or their privacy policies (if any). You will need to make your own independent judgement about whether to use any such sites, including whether to enter into any transactions to buy any products or services offered by or via them.</p>
             
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">10. Appmarketplace Terms Also Apply</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>10.1 The ways in which you can use the App and Documentation may also be controlled by the terms and conditions and policies of the application store or application marketplace being the digital distribution platform for computer software in the mobile device context from which you download the App (“AppMarketplace”) and

it is possible that those terms and conditions and policies will apply instead of these terms where there are differences between the two.</p>
             
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">11. Licence Restrictions</p>
            <div className='privacy-text'>
              <p><span  className='privacy-text-main'>11.1 You agree that you will:</span><br/>
              <div className='newIdent'>
11.1.1 not use the App if you are prohibited from doing so under applicable laws;<br/>

11.1.2 not rent, lease, sub-license, loan, provide, or otherwise make available, the App or the Services in any form, in whole or in part to any person without our prior written consent;<br/>

11.1.3 not copy the App, Documentation or Services, except as part of the normal use of the App or where it is necessary for the purpose of back-up or operational security;<br/>

11.1.4 not translate, merge, adapt, vary, alter or modify, the whole or any part of the App, Documentation or Services nor permit the App or the Services or any part of them to be combined with, or become incorporated in, any other programs, except as necessary to use the App and the Services on devices as permitted in these terms;<br/>

11.1.5 Not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the App or the Services nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile the App to obtain the information necessary to create an independent program that can be operated with the App or with another program (“Permitted Objective”), and provided that the information obtained by you during such activities is used only for the Permitted Objective and:<br/>

11.1.5.1 is not disclosed or communicated without our prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective;<br/>

11.1.5.2 is not used to create any software that is substantially similar in its expression to the App; and<br/>

11.1.5.3 is kept secure;<br/>

11.1.6 comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the App or any Service.
</div>
</p>
             
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">12. Acceptable Use Restrictions</p>
            <div className='privacy-text'>
              <p><span  className='privacy-text-main'>12.1 You must:</span><br/>
              <div className='newIdent'>
12.1.1 not use the App or any Service in any unlawful manner, because you are prohibited from doing so under applicable laws, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the App, any Service or any operating system;<br/>

12.1.2 not infringe our intellectual property rights or those of any third party in relation to your use of the App or any Service;<br/>

12.1.3 not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the App or any Service;<br/>

12.1.4 not use the App or any Service in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and<br/>

12.1.5 not collect or harvest any information or data from any Service or our systems or attempt to decipher any transmissions to or from the servers running any Service.
</div>
</p>
             
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">13. Intellectual Property Rights</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>13.1 All intellectual property rights in the App, the Documentation and the Services throughout the world belong to us (or our licensors) and the rights in the App and the Services are merely licensed and not sold to you. You have no intellectual property rights in, or to, the App, the Documentation or the Services other than the right to use them in accordance with the terms of this Licence.</p>
              
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">14. Our Responsibility for Loss or Damage Suffered by You</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>14.1 If we fail to comply with the terms of this Licence, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these terms or our failing to use reasonable care and skill. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these terms, both we and you knew it might happen.</p>
              <p className='privacy-text-main'>14.2 The App is for domestic and private use and only in jurisdictions where use of the App is not prohibited by applicable law. If you use the App for any commercial, business or resale purpose we will have no liability to you whether for any loss of profit, loss of business, business interruption, or loss of business opportunity or otherwise.</p>
            <p className='privacy-text-main'>14.3 We are not responsible for any loss or damage you suffer as a result of failure by you to comply with the terms of clause 5, including your obligations regarding the security of your private keys, PIN’s and mnemonics. However, we do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for fraud and fraudulent misrepresentation and for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors.</p>
            <p className='privacy-text-main'>14.4 If defective digital content that we have supplied damages a device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
            <p className='privacy-text-main'>14.5 The App and the Services have not been developed to meet your individual requirements. Please check that the functions of the App and the Services (see your chosen AppMarketplace and the Documentation) meet your requirements.</p>
<p className='privacy-text-main'>14.6 If our provision of the Services or support for the App or the Services is delayed by an event outside our control then we will notify you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event but if there is a risk of substantial delay you may contact us to end your contract with us and receive a refund for any Services you have paid for but not received.</p>
<p className='privacy-text-main'>14.7 Please be aware that internet transmissions may never be completely secure and that any message or information may be hacked or otherwise intercepted by others, even if there is a special notice that a particular transmission is encrypted.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">15. We May End Your Rights to Use the App and the Services if You Break these Terms</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>15.1 We may end your rights to use the App and Services at any time by contacting you if you break these terms. If what you have done can be put right, we will give you a reasonable opportunity to do so.</p>
              <p><span className='privacy-text-main'>15.2 If we end your rights to use the App and Services:</span><br/>
              <div className='newIdent'>
15.2.1 you must stop all activities authorised by these terms, including your use of the App and any Services;<br/>

15.2.2 you must delete the App from all devices in your possession and immediately destroy all copies of the App and Documentation which you have and confirm to us that you have done this; and<br/>

15.2.3 we may remotely access your devices and remove the App from them and cease providing you with access to the Services.
</div>
</p>
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">16. We May Transfer this Agreement to Someone Else</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>16.1 We may transfer our rights and obligations under these terms to another organisation. We will give you notice of any transfer by sending you details when you next start the App and we will ensure that the transfer will not affect your rights under the contract.</p>
             
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">17. You Need Our Consent to Transfer Your Rights to Someone Else</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>17.1 You may only transfer your rights or your obligations under these terms to another person if we agree in writing.</p>
             
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">18. No Rights for Third Parties</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>18.1 This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.</p>
             
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">19. If a Court Finds Part of this Contract Illegal, the Rest Will Continue in Force</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>19.1 Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
              
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">20. Even if We Delay in Enforcing this Contract, We can Still Enforce it Later</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>20.1 Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
              
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">21. Changes to these Terms</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>21.1 We may need to change these terms to reflect changes in law or best practice or to deal with additional features which we introduce. We will give you notice of any change by sending you details of the change or notifying you of a change when you next start the App by push notification. If you do not wish to accept the notified changes you will not be permitted to continue to use the App and the Service.</p>
              
            
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">22. Which Laws Apply to this Contract and Where You May Bring Legal Proceedings</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>22.1 This Licence is governed by English law. If there is any dispute or claim, you or we can bring legal proceedings in respect of the Licence in the English courts.</p>
              
            
            </div>
          </div>
        </div>
      
        
       
       
      </div>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-privacy'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
       
      </div>
    </div>
  );
}; 
export default MobileAppPrivacy;