import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg from '../../../assest/phoneImg/Palm  based.png';
import './palm-based.styles.scss';

export const PalmBased = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Palm Based BioMetrics' uris='Palm Based BioMetrics' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Providing A Personalized, Advanced, And More Secure Touch To The Traditional Authentication System.'
    >
      <p>
        We at EarthId, are always looking for emerging technologies that can offer promising
        solutions in terms of user authentication. Palm Vein Verification System is one such
        reliable technology that offers a greater level of security and personalization to the
        authentication system.
      </p>
      <p>
        Palm Based Biometrics aims to recognize individuals with their unique palm vein structure.
        This contactless and non-intrusive solution requires the user to show their palms to the app
        camera. Once done, an infrared LED illumination highlights the vein. Every person has a
        unique structure and position of veins, hence the image acquired by the infrared imager is
        distinctive. It is difficult to duplicate the vein patterns of an individual, hence making
        this solution a robust identity authentication solution.
      </p>
      <p>
        Palm Based Verification System only requires a single image for identity authentication,
        turning it into a faster verification process. Let EarthId experts show you the potential of
        this emerging technology.
      </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

//dafh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-palm'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Platform / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
