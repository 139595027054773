import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import './privacy.styles.scss';

const AppPrivacy = () => {

 const headerContent=`"EarthID", "we", "our" or "us" refers to EarthID TechnologyLtd., a company incorporated in England and Wales under number 10979596 and having its registered office at International House, 12 Constance St, London E16 2DQ. EarthID is registered with the Information Commissioner’s Office as a data controller with registration number ZA557020. EarthID can also be contacted by email: <a href="mailto:privacy@myearth.id" target="_blank">privacy@myearth.id</a>

  This App Privacy Policy applies to the use of our App, in addition to the terms and conditions set out in our Mobile Application End-User Licence - <a href="https://myearth.id/mobile-application-end-user-licence" target="_blank">https://myearth.id/mobile-application-end-user-licence</a> - (“Licence”). This App Privacy Policy does not apply to the use of our website where personal data is collected and handled differently, and which is subject to a different privacy policy <a href="https://myearth.id/privacy-policy" target="_blank">https://myearth.id/privacy-policy</a> (“Web Privacy and Cookies Policy”)
  
  Our App works on a zero-knowledge basis. At its core is privacy-by-design. The result of this is that whilst “personal data”, as defined in law, may be supplied in the registration process and during the operation of the App as you carry out transactions, neither we nor anyone else can access your personal data because neither we nor anyone else has access to either your private key or your mnemonic. The App uses blockchain and IPFS technology to achieve zero knowledge. All functions of the EarthID App are performed on a zero-knowledge basis. Legislation requires EarthID to make certain statements about personal data and data processing, but they are not easily applicable to the security factors of our zero knowledge and privacy-by-design ethos.
  
  Our App is available to users globally. We do not give you permission to use the App if you are prohibited from doing so under applicable laws. Users can access the App via the application store or application
  
  marketplace being the digital distribution platform for computer software in the mobile device context from which you download the App (“AppMarketplace”), subject also to the terms and conditions applicable to the relevant AppMarketplace. If you are not in a jurisdiction in which we offer our App, you will not be able to use it. We are working to make EarthID and our App a global service but must comply with each jurisdiction’s laws. Full details are set out in our Licence.
  
  This App Privacy Policy sets out how we will treat any personal data we obtain from you when you download and use our App and it is intended to help you understand how we deal with it and your queries. By providing your albeit limited personal data to us, you understand that we may collect, process and use the personal data you provide in the ways described in this App Privacy Policy.
  
  Please note that we may need to change this App Privacy Policy from time to time. We will send a notification (as detailed below) when any changes are made.
  
  EarthID is the data controller of all “personal data” meaning any information relating to an identified or identifiable natural person, as defined in Article 4 of the General Data Protection Regulation (Regulation EU 2016/679) (“GDPR”) (or under equivalent applicable laws) provided to us by “data subjects” (an identified or identifiable natural person) through or in connection with the use of our mobile application (“App”).`

  return(
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='' uris='' />
    <OverlapBoxPrivacy
      title='APP PRIVACY POLICY'
      header={headerContent}
    >

    </OverlapBoxPrivacy>

    <Getstarted/>
    <Footer />
  </div>
);
  }

export const OverlapBoxPrivacy = ({ header, title, children }) => {
  return (
    <div className='overlap-box-privacy'>
      <div className='privacy-content'>
        <p className='privacy-main-title'>{title}</p>
        <p className="headerBlock"><div dangerouslySetInnerHTML={{ __html: header }} /></p>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">1. Personal Data</p>
            <div className='privacy-text'>
              <p><span className='privacy-text-main'>1.1 We categorise personal data as follows:</span><br/>
              <div className='newIdent'>
1.1.1 Identity Data includes first name, last name, over 18 status (on a true response basis only), digital identity or similar identifier, Photo ID, biometric data, push notification phone ID and the UUID

(Unique Universal Identifier) being the ID associated with your device and generated by your device during the registration process;<br/>

1.1.2 Contact Data includes the UUID or push notification phone ID;<br/>

1.1.3 Technical Data includes the device and operating system you use to access our App;<br/>

1.1.4 Profile Data includes your private keys, PIN’s and mnemonics; and<br/>

1.1.5 Usage Data includes information about how you use our App and services from transactions ordered by you through the App and whether they are successfully concluded or not and EarthID Tokens allocated to you through the App.
</div>
</p>
            </div>
          </div>
        </div>

        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">2. Legal Bases for the Processing of Personal Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>2.1 The zero-knowledge nature of the EarthID App means that we do not have access to your personal data except for the UUID and your over 18 status (on a true response basis only). All other personal data that you may supply through your use of the App is encrypted and is on a zero-knowledge basis and we cannot access any of it.</p>
              <p className='privacy-text-main'>2.2 We have set out below a description of the ways we may use your personal data following our zero knowledge and privacy-by-design principles, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate. Note that we may process your personal data in more than one way depending on the specific purpose for which we need to use your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the list below.</p>
              <p><span className='privacy-text-main'>2.3 EarthID’ does not rely on consent as a legal basis for processing your personal data. We will not send you direct marketing communications.</span><br/>
              <div className='newIdent'>
2.3.1 To register you as a new user (to validate your Photo ID with your biometrics and then following a positive validation and encryption, your OCR and Photo ID is stored in your, i.e. the user’s, EarthID on a zero-knowledge basis and we have no access to that personal data).<br/>

· We will use Identity and Contact personal data<br/>

· This is to effect the performance of a contract with you<br/>

2.3.2 To send push notifications of our App updates, and issues relating to Service and downtime (in order to try to provide more information to users beyond posting an error code).<br/>

· We will use Identity and Contact personal data<br/>

· This is<br/>

o to try to effect the performance of a contract with you;<br/>

o necessary to comply with a legal obligation; and<br/>

o necessary for our legitimate business interests (in order to manage our users’ expectations in relation to updates and service availability)<br/>

2.3.3 To manage our relationship with you which will include sending push notifications when we make changes to our Licence or App Privacy Policy so end users are aware that changes have been made.<br/>

· We will use Identity and Contact personal data<br/>

· This is<br/>

o to effect the performance of a contract with you; and<br/>

o necessary to comply with a legal obligation (or otherwise desirable for transparency).<br/>

2.3.4 To generate within the App, on the user’s device, a private key.<br/>

· We will use Identity and Contact personal data<br/>

· This is<br/>

o to effect the performance of a contract with you; and<br/>

o necessary to comply with a legal obligation.<br/>

2.3.5 To manage the data storage aspects of the EarthID solution which will initially be hosted in IPFS channel Nodes in the EarthID ecosystem. Each node will hold a copy of the data, but that data is encrypted and will only be accessible by the user with their private key. The private key can be derived from the mnemonic which the end user controls.<br/>

· We will use all categories of personal data listed in clause 1 above<br/>

· This is<br/>

o to effect the performance of a contract with you; and<br/>

o Necessary for our legitimate interests (in order to avoid placing an undue load on the existing network in the initial stages of our business development).<br/>

2.3.6 We will use digital identity to allow us to allocate EarthID Tokens to be placed on the users’ dashboards within the App.<br/>

· We will use Identity and Usage personal data<br/>

· This is to effect the performance of a contract with you<br/>

2.3.7 To administer and protect our business and our App (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data).<br/>

· We will use Identity and Contact and Technical personal data<br/>

· This is<br/>

o necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a<br/>

business reorganisation or group restructuring exercise); and<br/>

o necessary to comply with a legal obligation<br/>

2.3.8 To use data analysis to improve our App, products/services, customer relationships and experiences.<br/>

· We will use Technical and Usage personal data<br/>

· This is necessary for our legitimate interests (to define types of customers for our products and services, to keep our App updated and relevant, to develop our business and to inform our marketing strategy)<br/>

2.3.9 For age verification purposes we may share your UUID and your over 18 status (on a true/false response basis only) with the API user.<br/>

· We will use only the personal data listed above<br/>

· This is necessary to comply with a legal obligation<br/>

· This is to effect the performance of a contract with you<br/>

2.3.10 Where we are required to share personal data to protect against fraud, or with a regulatory authority or by operation of law.<br/>

· We will use all categories of personal data listed in clause 1 above<br/>

· This is necessary to comply with a legal obligation<br/>

2.3.11 To obtain professional advice from our third party professional advisers, providing always that they are under an obligation of confidence in relation to that personal data.<br/>

· We will use all categories of personal data listed in clause 1 above<br/>

· Necessary for our legitimate interests (in order to take professional advice in relation to the operation of our business)<br/>

2.3.12 To share and/or transfer personal data which is always encrypted so it can only be accessed by the end user, within our business in order to allow for smooth operation of the App and Service and to facilitate business operations within our own company and group of companies, or on a sale or change of control of our business<br/>

· We will use all categories of personal data listed in clause 1 above<br/>

· Necessary for our legitimate interests (in order for our personnel to operate the business and to allow for the development of a group corporate structure within our business or to execute a sale or change of control of our business)
</div>
</p>

            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">3. Information Collection</p>
            <div className='privacy-text'>
              <p><span className='privacy-text-main'></span>3.1 As stated above, all your personal data that you may supply through your use of the App is encrypted and is on a zero knowledge basis and except for your over 18 status (on a true/false response basis only) and the personal ID associated with your device and generated by your device during the registration process, we cannot access any of it. Ways in which you may provide us with personal data via our App include, for example,<br/>
              <div className='newIdent'>
3.1.1 downloading our App to your device;<br/>

3.1.2 registering on our App; and<br/>

3.1.3 carrying out transactions via our App.
</div>
</p>
              <p className='privacy-text-main'>3.2 It is your choice to provide us with personal data. Where we need to collect personal data under the terms of our contract with you and you choose not to provide the relevant personal data, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us, but we will notify you if this is the case at the time.</p>
              <p className='privacy-text-main'>3.3 The information we will collect from you is limited to data concerning the use of our App, and other communication data including the resources that you access.</p>
              <p className='privacy-text-main'>3.4 We also collect, use and share Aggregated Data. Aggregated Data may be derived from your personal data but is anonymised so it is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate Technical Data to calculate the percentage of users accessing the App with a specific device.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">4. Third Party Use of Personal Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>4.1 In order to validate your Photo ID with your biometrics, your personal data will be required by a third party service provider, who will (a) perform that check service and return the pass/fail result of that check to EarthID and a true/false result of your over 18 status and (b) will delete all personal data you agree to provide for that check service. EarthID will retain the pass/fail result of your ID check and the true/false result of your over 18 status.</p>
              <p className='privacy-text-main'>4.2 In order to process a transaction through the App certain personal data may be requested by the merchant and EarthID may supply the pass/fail result of your ID check and the true/false result of your over 18 status. In relation to all other personal data, you will have control over whether this is sent to the relevant merchant in relation to each transaction. The processing of personal data by the merchant is then subject to their privacy policy and terms and conditions and we have no control over that.</p>
              <p className='privacy-text-main'>4.3 EarthID does not sell personal data. However, we may share personal data with third parties who are our data processors to the extent necessary to deliver the updates for which a user has subscribed. Personnel employed by these entities generally may have access to a user’s personal data for the purpose of providing the

updates. However, these entities are contractually prohibited from using such information for any other purpose.</p>
              <p>4.4 We do not disclose personal data to unaffiliated third parties, except as described in this privacy policy.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">5. Cookies</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>5.1 The App generates a UUID (Unique Universal Identifier) for each device that uses the App so that we can send push notifications (using a third party provider) which are required for the operation of the App.</p>
              <p className='privacy-text-main'>5.2 Cookies will very likely be used in any third party site you use including merchants sites and so you should refer to the privacy and cookie policy of the relevant merchant in each case.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">6. Change of Purpose</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>6.1 We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">7. Protection of Information</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>7.1 Information posted on IPFS is encrypted and is private, requiring your IPFS hash. Transaction records and security verification records are encrypted (and always require your private key/mnemonic for access which we do not have) and stored in a distributed ledger blockchain environment. Personal data (as defined above) is posted to a permissioned IPFS. By design all information and data posted will remain on the blockchain/IPFS for so long as that instance of the blockchain/IPFS exists. You may disassociate yourself from the encrypted information on the blockchain/IPFS at any time by destroying your private keys. Within the App, you may exercise your right to erasure pursuant to Article 17 GDPR in relation to personal data which is stored on permissioned IPFS.</p>
              <p className='privacy-text-main'>7.2 We have established and maintain internal policies and procedures to protect personal data from unauthorised use or disclosure. For example, our security methods may include device safeguards, secured files and buildings and oversight of our third-party service provides or similar entities. That said, no data transmission or storage system can be guaranteed to be 100% secure and, thus, we do not guarantee that these safeguards will prevent the interception of personal data transmitted via the internet or that personal data stored in our systems, or that is otherwise in our care, will be safe from unauthorised use or some other form of intrusion by third-parties. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">8. Third Party Websites</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>8.1 Our App may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements and we do not make any representations or warranties regarding the content or accuracy of materials on such third party sites. Such sites may have Terms of Use, Privacy Policies, or security practices different from EarthID and we do not endorse or recommend, and hereby disclaim any liability for, any information or products offered at such sites. When you leave our App, we encourage you to read the privacy notice of every site you visit.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">9. Transfers of Personal Data</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>9.1 Personal data of individuals located in the European Economic Area (“EEA”) or Australia may be transferred to a jurisdiction outside the EEA or Australia because EarthID technology is based on a decentralised system and distributed ledger and we use service providers with global systems in hosting and in executing identity verification and transactions via the A

receive any personal data. Your personal data will be held by us on the same no knowledge basis wherever it is stored. We reiterate that it cannot be accessed because access requires your private key derived from your mnemonic which only you hold.</p>
              <p>9.2 If we transfer any personal data, which you may instruct us to do by operation of the App, we will only transfer data to service providers in countries that have been deemed to provide an adequate level of protection by the European Commission. If we use service providers, this will be on contractual terms which give personal data the same protection as it has in the EEA or Australia (as applicable). If we use service providers in the USA, we will only use service providers that are part of the Privacy Shield which requires the service providers to give personal data similar protection as it has in the EEA. If you require further information on where your data may be transferred to please get in touch with us.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">10. Retention of Personal Data Information</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>10.1 We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements which we expect to be for no more than for seven years after they cease being users in line with taxation limitation period purposes.</p>
              <p className='privacy-text-main'>10.2 To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
              <p className='privacy-text-main'>10.3 We may use Aggregated Data for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">11. Individual Rights</p>
            <div className='privacy-text'>
              <p><span  className='privacy-text-main'>11.1 Under certain circumstances, you may have rights under data protection law in relation to your personal data which is held by us as follows especially if you are a UK, EEA, or Australian citizen, such as:</span><br/>
              <div className='newIdent'>
· Request access to your personal data<br/>

· Request correction of your personal data<br/>

· Request erasure of your personal data<br/>

· Object to processing of your personal data<br/>

· Request restriction of processing your personal data<br/>

· Request transfer of your personal data<br/>

· Right to withdraw consent
</div>
</p>
              <p className='privacy-text-main'>11.2 These rights are all subject to some limitations as provided for by applicable law. If you wish to exercise any of the rights set out above, or have a complaint about how we handle your personal data, please contact us at <a href="mailto:privacy@myearth.id" target="_blank" rel="noreferrer">privacy@myearth.id</a></p>
              <p className='privacy-text-main'>11.3 In line with GDPR you will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>
              <p className='privacy-text-main'>11.4 We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
              <p className='privacy-text-main'>11.5 We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is

particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
              <p>11.6 Individuals located in the UK and Australia have the right to lodge a complaint about the processing of their personal data with the supervisory authority of the data controller, which is the Information Commission’s Office (in the UK) and the Office of the Australian Information Commissioner (in Australia).</p>
            </div>
          </div>
        </div>
       
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">Effective as of 5 October 2023</p>
            
          </div>
        </div>
        
      </div>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-privacy'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
       
      </div>
    </div>
  );
}; 
export default AppPrivacy;