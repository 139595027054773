import React from 'react';
import './productpage.styles.scss';
import digitalImage from './../../../assest/Solutions - Products/Digital KYC.png'
import passwordlessImage from './../../../assest/Solutions - Products/Passwordless Authentication.png'
import ssiImage from './../../../assest/Solutions - Products/SSI.png'

import { Footer } from '../../../components/essentials/footer/footer.component';
// import ReactPlayer from 'react-player';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';

export const Product = () => {
  return (
    <div className='solution-platform'>
      <NavBar />
      <HeaderHalf title='Products' uris='Products' />
      <div className="image-content-card fixed-width">
        <h2>DigitalKYC</h2>
        <div className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="img">
                <img src={digitalImage} alt="DigitalKYC" className="img-fluid"></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text">
                <div className="sub-title">Digitizing identity verification through technology innovations.</div>
                <div className="content-text">
                  <p className="content-p lead">Know your customers better without the need to get into messy physical veriﬁcation
                  with our advanced Digital KYC Services. EarthId is technically equipped to help you
                  automate KYC authentication by using state-of-the-art technologies. Complying with KYC regulations and advanced theft detection techniques, we ensure the KYC
                  veriﬁcation is swift yet fraudulent-free.
                  </p>
                  <p className="content-p lead">Our solution leverages the use of third generation ledger to systematize and
                  automate the Customer Due Diligence Process. Using the capabilities of AI &amp; ML, our
                  Digital KYC Services have been tailored to oﬀer a seamless end-to-end customer
                  onboarding in a more faster and compliant manner.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="highlight text-right font-italic text-right">EarthId ensures that customer onboarding is swift, customer veriﬁcation is quick and overall engagement is frictionless.</div>
          <div className="">
            <h2 className="sub-title">Solution Highlights</h2>
            <div className="content">
              <div className="text-title-content-highlight">Swift Client Onboarding</div>
              <div className="highlight text-left font-italic text-title-content-highlight ">Quick and easy digital onboarding of new users on online platforms using pre-verified information.</div>
              <p className="content-p lead">Using advanced onboarding processes like facial recognition, double authentication, as well as advanced biometric techniques like Palm Technology, EarthId offers a unique yet powerful client onboarding process.</p>
              <div className="text-title-content-highlight">Data Compliance</div>
              <div className="highlight text-left font-italic text-title-content-highlight ">Data compliant to protect users against data breaches at the same time helping firms in identity proofing and corroboration.</div>
              <p className="content-p lead">Owing to the drastic increase in cyber attacks, customers using fraudulent ways to tamper with data, we at EarthId take data protection very seriously.
              Understanding the criticality of data protection, we comply with regulations like GDPR, PDPA, and PDPM.
              </p>
              <div className="text-title-content-highlight">Cost-Effective</div>
              <div className="highlight text-left font-italic text-title-content-highlight ">Hassle-free onboarding with least dependency on highly redundant and non-scalable processes like collection and verification of physical documents.</div>
              <p className="content-p lead">By automating the entire onboarding process and via the digitization of document gathering, EarthId helps clients reduce the cost of compliance.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="image-content-card fixed-width">
        <h2>Passwordless Authentication</h2>
        <div className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="img">
                <img src={passwordlessImage} alt="Passwordless Authentication" className="img-fluid"></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text">
                <div className="sub-title">Experience the boundless potential of modern authentication.</div>
                <div className="content-text">
                  <p className="content-p lead">Removing reliance on usernames and passwords, our solutions bring to you keyless
                  entry authentication. In todays world of escalated cybersecurity breaches, user
                  credentials leave people vulnerable to hackers attempts at credential stuffing, brute
                  force, and phishing attacks. Our holistic approach to passwordless authentication
                  helps eradicate the need to remember complex sequences of characters. EarthId
                  doesn’t let login workflows interrupt the user engagement by providing invincible
                  keyless authentication using state-of-the-art biometric and machine learning
                  technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="highlight text-right font-italic text-center">Go passwordless with EarthId and eliminate the risk of phishing, password reuse, and fraud.</div>
          <div className="">
            <h2 className="sub-title">Solution Highlights</h2>
            <div className="content">
              <div className="text-title-content-highlight">Next-Gen Login</div>
              <p className="content-p lead">Authenticate online users with password-less technology using pre-verified identity and dynamic QR codes.
      </p>
              <div className="text-title-content-highlight">Contactless &amp; Keyless Entry</div>
              <p className="content-p lead">Manage access to restricted areas using the EarthId app with the help of biometric user authentication.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="image-content-card fixed-width">
        <h2>Self Sovereign Identity</h2>
        <div className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="img"><img src={ssiImage} alt="Self Sovereign Identity" className="img-fluid"></img>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text">
                <div className="sub-title">Making users take complete charge over their data.</div>
                <div className="content-text">
                  <p className="content-p lead">EarthId brings to you the future of data management, where users manage their
                  digital identity by taking complete control over where, when, and with whom they
                  share their data.
                  </p>
                  <p className="content-p lead">Today’s traditional identity management models aren’t capable of providing users
                  with the freedom of Self Sovereign Identity. Using a third-generation Distributed
                  Ledger Technology, Hedera Hashgraph along with strong cryptography, EarthId
                  solves the critical problems with existing centralized and monolithic Identity
                  Management Solutions, cost-effectively and securely.
                  </p>
                  <p className="content-p lead">Our decentralized identity ecosystem is based on a credential-based access control
                  where:
                  </p>
                  <ul className="ul-container">
                    <li className="lead">The information owner/applicant stores the data and is notified as well as asked
                    permission, every time a verifier tries to access confidential data.
                     </li>
                    <li className="lead">The issuer/respective authority verifies the applicant’s credentials and has the full
                    right to revoke it once the credentials are expired.
                     </li>
                    <li className="lead">The verifier sends a request to the applicant to check for verified credentials rather
                    than undergoing a complicated verification process.
                     </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="highlight text-right font-italic text-center m-width">With EarthId’s Self Sovereign Identity, the verifier saves money and time, the applicant has the right to data confidentiality, and
          the issuer can simply issue and revoke credentials digitally. Hence, making identity verification a convenient process for all.
      </div>
          <div className="">
            <h2 className="sub-title">Solution Highlights</h2>
            <div className="content">
              <ul className="ul-container">
                <li className="content-p lead">The applicant’s digitally verified credentials are managed by the applicant, from the safety of their phone.</li>
                <li className="lead">Sharing information with the verifier is secure and easy with an SSI-based digital wallet.</li>
                <li className="lead">EarthId is highly resilient to cyber attacks, ensuring maximum data confidentiality.</li>
                <li className="lead">The solution can easily be integrated with any system using REST APIs.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-product'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
