import React from 'react';
import './face-id.styles.scss';
import { Link } from 'react-router-dom';
import dec_identity from '../../../assest/Home/circular_icons/dec_identity.png'
import passwordless from '../../../assest/Home/circular_icons/passwordless.png'
import zero_proof from '../../../assest/Home/circular_icons/zero_proof.png'
import biometrics from '../../../assest/Home/circular_icons/biometrics.png'
import verifiable from '../../../assest/Home/circular_icons/verifiable.png'
import wallets from '../../../assest/Home/circular_icons/wallets.png'
import decentralization from '../../../assest/Home/circular_icons/decentralization.png'
import security from '../../../assest/Home/circular_icons/security.png'
import digitalization from '../../../assest/Home/circular_icons/digitalization.png'
// import icon_dynamic_image from '../../../assest/Home/icon_dynamic_image.png';
import { useState } from 'react';

export const FaceId = () => {
  const [content, togglecontent] = useState(0);
  const [btns] = useState([
    {
      name: 'btn-1',
      hidden: true,
      content:
        'As an Identity Service Provider (IdSP), Attribute Service Provider (ASP), and reusable ID provider, EarthID enhances security, streamlines KYC processes, and reduces onboarding time.',
      title: 'Identity Verification',
      link: '/solution/identity-verification',
      image: dec_identity
    },
    {
      name: 'btn-1',
      hidden: true,
      content:
        'Authenticate online users with password-less technology using pre-verified identity and dynamic QR codes.',
      title: 'Passwordless Authentication',
      image: passwordless,
      link: '/solution/passwordless'
    },
    {
      name: 'btn-6',
      hidden: true,
      content:
        'Safeguard children and young people by ensuring that age-restricted goods and services are access only by those who meet the legal age requirements.',
      title: 'Age Verification',
      image: zero_proof,
      link: '/solution/age-verification'
    },
    {
      name: 'btn-9',
      hidden: true,
      content:
        'A data security technique that replaces sensitive info with unique, non-sensitive tokens, reducing the risk of breaches and unauthorized access.',
      title: 'Tokenization and Data Vault',
      link: '/solution/data-tokenization',
      image: biometrics
    },
    {
      name: 'btn-3',
      hidden: true,
      content:
        'Provide a secure, efficient way to authenticate qualifications and achievements with verifiable digital credentials. Powered by blockchain and cryptographic techniques, they ensure trustworthy, tamper-proof digital identities.',
      title: 'Verifiable Digital Credentials',
      image: verifiable,
      link: '/solution/digital-credentials'
    },
    {
      name: 'btn-4',
      hidden: true,
      content:
        'Multi-channel Web3-ready wallets for secure and seamless management of IDs, digital assets and payments, without creating a digital divide.',
      title: 'Digital Wallets',
      image: wallets,
      link: '/solution/id-wallet'
    },
    {
      name: 'btn-2',
      hidden: true,
      title: 'Decentralization',
      content: 'Distributed ledger technology (DLT) for audit and trace, immutable proofs and provenance.',
      image: decentralization
    },
    {
      name: 'btn-7',
      hidden: true,
      content:
        'Security by design, and not an afterthought, to achieve data-protection, fraud-prevention and user-privacy.',
      title: 'Security',
      image: security
    },
    {
      name: 'btn-8',
      hidden: true,
      content:
        'Driving digital transformation through digitization and optimization of processes, while enhancing user experience.',
      title: 'Digitization',
      image: digitalization
    },
  ]);
  // console.log(btns, toggleBtns, togglecontent);

  const handleChange = e => {
    console.log(e.target.value);
    togglecontent(e.target.value);
  };

  return (
    <div className='faceId-content'>
      <div className='faceId-content_text'>
        <p className='faceId-content_text-2'>
        A Global Award-Winning Decentralized Identity Platform
        </p>
        <p className='faceId-content_text-3'>
        EarthID’s award-winning Decentralized Identity platform allows secure and frictionless exchange and verification of identity information. EarthID helps organisations reduce identity frauds, optimize costs and drive sustainability, while empowering users with data ownership, privacy and consent.
          {/* <p className='faceId-content_text-3-spaning'>
            Our digital KYS platform allows you to manage the identity of your stakeholders across
            banking, finance, insurance, healthcare, gaming, and many more industries across private
            and public sectors..
          </p> */}
        </p>
      </div>
      <div className='faceId-content_dynamic'>
        <div className='faceId-content_dynamic-1'>
          <div className='faceId-content-text-block'>
            <p className='faceId-content_dynamic-1-title'>{btns[content].title}</p>
            <p className='faceId-content_dynamic-1-desc'>{btns[content].content}</p>
            {/* Render "Read more..." link if available */}
            {btns[content].link && (
              <Link to={btns[content].link} className='read-more-link' onClick={() => window.scrollTo(0, 0)}>Read more...</Link>
            )}
          </div>         
        </div>
        <div className='faceId-dyanmic-img-div'>
            <img src={btns[content].image} className='faceId-content_dynamic-2-img'></img>
          
          <button className='dynamic-btn dynamic-btn_1' value='0' onMouseOver={handleChange}>
            btn-1
          </button>
          <button className='dynamic-btn dynamic-btn_2' value='1' onMouseOver={handleChange}>
            btn-2
          </button>
          <button className='dynamic-btn dynamic-btn_3' value='2' onMouseOver={handleChange}>
            btn-3
          </button>
          <button className='dynamic-btn dynamic-btn_4' value='3' onMouseOver={handleChange}>
            btn-4
          </button>
          <button className='dynamic-btn dynamic-btn_5' value='4' onMouseOver={handleChange}>
            btn-5
          </button>
          <button className='dynamic-btn dynamic-btn_6' value='5' onMouseOver={handleChange}>
            btn-6
          </button>
          <button className='dynamic-btn dynamic-btn_9' value='8' onMouseOver={handleChange}>
            btn-9
          </button>
          <button className='dynamic-btn dynamic-btn_8' value='7' onMouseOver={handleChange}>
            btn-8
          </button>
          <button className='dynamic-btn dynamic-btn_7' value='6' onMouseOver={handleChange}>
            btn-7
          </button>
        
        </div>
        
      </div>
    </div>
  );
};