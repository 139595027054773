import React from 'react';
import './requestsalespage.styles.scss';
import { Col, Row } from 'react-bootstrap';
import { Footer } from '../../components/essentials/footer/footer.component';
import { Reqsalesnavbar } from '../../components/essentials/navbar/reqsalesnavbar.component';
// import monzo_logo from '../../assest/Home/monzo-logo.png';
// import united_logo from '../../assest/Home/united-airlines-logo-vector-eps-ai-free-download.png';
// import hsbc_logo from '../../assest/Home/HSBC-logo.png';
// import rappi_logo from '../../assest/Home/rappi_logo.png';
import SalesForm from '../../components/requestsalescomponents/SalesForm';
import { PartnersContent } from '../aboutpage/partners/partners.component';

const RequestSalesPage = () => (
  <div className='requestalepage'>
    <Reqsalesnavbar />
    <div className='header'>
      <div className='container pt-5'>
        <HeaderHalf title='Request Sales Information' uris='Request Sales Information' />
        <Row className='form-sales'>
          <Col lg='5' xl='5'>
            <SalesForm />
            <p className='text-white text-center px-4 font-rubik mt-4'>
              By submitting this form, you agree to the Privacy Policy of our website.
            </p>
          </Col>
        </Row>
      </div>
    </div>
    <PartnersContent title="Partners" classN='logo-partners' />
    <div className='footer'>
      <Footer isMoreInfo={true} />
    </div>
  </div>
);

export default RequestSalesPage;

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-requestsales mt-5 pt-5'>
      <div className='headerHalf-content mb-5'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Home / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
