import React from 'react';
import './compliance-page.styles.scss';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import playing from '../../../assest/7 - Solutions - Compliance/Compliance02.png';

export const CompliancePage = () => {
  return (
    <div className='solution-platform'>
      <NavBar />
      <HeaderHalf title='Compliance' uris='Compliance' />
      <OverlapBox
        title=''
        className='overlap-box'
      >
        <div>
          <div>
            <div className="compliance-block">
              <p className="compliance-sub-title">Borderless Access To Global Services With EarthID’s Global Regulation-compliant Platform</p>
              <p className='compliance-text'>
                Under General Data Protection Regulation (GDPR), EarthID protects the personal data 
                and privacy of transactions made within EU member states. As a GDPR compliant identity 
                management platform, we comply with all regulatory requirements that strengthen the 
                data privacy and security of every individual within the EU.
        </p>
              <p className='compliance-text'>
                Our identity verification platform uses a secure API to connect with data sources globally without 
                compromising on data confidentiality. Our ultra-modern identity solution is quick to identify 
                high-risk individuals as well as easily indicates potential threats pertaining to identity theft and fraud.        </p>
              <div className='image-compliance-container'>
                <img src={playing} className='img-for-compliance' alt='compliance-img' />
              </div>
            </div>
            <div className="compliance-block">
              <p className="compliance-sub-title">
                Meet Your Business Goals Without Having To Worry About Legal & Governmental Regulations
            </p>
              <p className='compliance-text'>
                Staying compliant with the manual verification process can significantly add to your operational costs. EarthID’s <strong>automated identity verification</strong> platform can help streamline your business processes while reducing internal costs.
        </p>
              <p className='compliance-text'>
                You no more need to compromise your user experience while trying to meet regulatory requirements for the identity verification process. Switch to EarthID’s GDPR compliant Decentralized Identity Management Platform to reduce operational costs and scale up faster than ever.
          </p>
            </div>
            <div className="compliance-block">
              <p className="compliance-sub-title">
                Why EarthID?
            </p>
              <ul className='compliance-list'>
                <li>A cost-effective alternative to costly legacy compliance & KYC systems</li>
                <li>Reduced employee-base for manual identity verification</li>
                <li>Eliminate reputational risks with customers and regulators</li>
                <li>Safer Onboarding with assured compliance</li>
              </ul>
            </div>
          </div>
        </div>
      </OverlapBox>
      <Getstarted/>
      <Footer />
    </div>
  );
};

//odfgh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-compliance'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
