import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

import cancel from '../../assest/Home/cancel.png';
import './pop.styles.scss';

export const SubscriberPopup = ({ popup, setPopup }) => {
  // console.log('i am a popup watch me:', popup);

  const handleChange = e => {
    // console.log('I am a cancle button popup value:', popup);
    setPopup({ show: false, status: '' });
  };

  const showMessage = (statusCode) => {
    if (statusCode === 200) {
      return (<div className='popupText'>
        Thank you for subscribing to our Newsletter
      </div>);
    } else if (statusCode === 404) {
      return (<div className='popupText'>
        Kindly use valid email ID
      </div>);
    } else if (statusCode === 400) {
      return (<div className='popupText'>
        Invalid email ID
      </div>);
    } else {
      return (<div className='popupText'>
        Current Email ID already subscribed
      </div>);
    }
  }

  return (
    <Dialog open={popup.show} maxWidth={'md'}>
      <DialogContent>
        <div className='popup-dialog subscriber-dialog'>
          <div className='cancel-btn-block'>
            <button className='cancel-btn cancel-btn-sub' onClick={handleChange}>
              <img src={cancel} alt='toCncl' className='cancel-img' />
            </button>
          </div>
          {showMessage(popup.status)}
        </div>
      </DialogContent>
    </Dialog>
  );
};
