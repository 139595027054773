import React from 'react';
import './idv.styles.scss';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import passwordless from '../../../assest/2 - Solutions - Platforms/search-problem.svg';
import ai from '../../../assest/2 - Solutions - Platforms/padlock (1).svg';
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import photoOnPhone from '../../../assest/2 - Solutions - Platforms/EarthId-KYS-01.png';
import phoneImg from '../../../assest/idv/Intro (1).png';
import { OverlapBoxFeature } from '../features-page/features-page.component';
import global from '../../../assest/25 - Age Verification/global coverage.png'
import global1 from '../../../assest/idv/How solution works (1).png'
import global3 from '../../../assest/idv/Benefits.jpg'
//import { FaceId } from '../../../components/homepageComponents/face-id/face-id.component';

import tobacco from '../../../assest/idv/icons (2)/FS.png';
import gamble from '../../../assest/idv/icons (2)/Health.png';
import alcohol from '../../../assest/idv/icons (2)/telco.png';
import cbd from '../../../assest/idv/icons (2)/Travel.png';
import content from '../../../assest/idv/icons (2)/GS.png';
import pharma from '../../../assest/idv/icons (2)/Voting.png';
import diy from '../../../assest/idv/icons (2)/Social.png';
import weapon from '../../../assest/idv/icons (2)/Edu.png';


export const IDV = () => {
  return (
    <div className='solution-platform'>
      <NavBar />
      <HeaderHalf title='Identity Verification' uris='Identity Verification' />
      <OverlapBox title='Secure, Seamless Identity Verification for Your Business'>
        <div className='platform-content'>
          <p className='cards-div-title'>
          In today’s fast-paced world of evolving regulations and advancing technology, your identity solutions need to be both adaptable and secure. EarthID integrates state-of-the-art technology with regulatory compliance to deliver exceptional user experiences while safeguarding your business.
        </p>
          <div className='cards-div-platform'>
            <MediumCard
              icon={kysBulletPoint}
              title='Accelerate Business Growth'
              desc='Grow your business with quality customers by preventing fraudulent accounts, ensuring compliance, and improving the speed of identity verification.'
            />
            <MediumCard
              icon={passwordless}
              title='Prevent Identity Fraud'
              desc='With EarthID’s AI-powered biometrics and state-of-the-art technology avoid identity frauds and stop fraudsters to enter your business ecosystem.'
            />
            <MediumCard
              icon={ai}
              title='Meet Regulatory Compliance'
              desc='Meet Know Your Customer (KYC) and Anti-Money Laundering (AML) requirements by complying with regulations affecting your business'
            />
          </div>
        </div>
      </OverlapBox>

      <OverlapBoxTechno
      img={phoneImg}
      title='Privacy Preserving Approach to Identity Verification'
    >
      <p>
      EarthID is a decentralized identity platform certified by UK DIATF, offering identity verification across over 220 countries and territories. Our solution provides a seamless, privacy-focused, and compliant way to verify government-issued IDs like passports, national IDs, and driver’s licenses, ensuring adherence to regulations such as GDPR, DPDP, and CCPA. Leveraging AI-powered verification and a decentralized approach, EarthID minimizes the risk of breaches by avoiding centralized data storage.
      </p>
      <p>
      As an Identity Service Provider (IdSP), Attribute Service Provider (ASP), and reusable ID provider, EarthID enhances security, streamlines KYC processes, and reduces onboarding time. 
      </p>
      <p>
      By offering seamless API integration, EarthID enhances both privacy and security, ensuring a frictionless experience for you and your customers. With its decentralized identity model, customers maintain full control over their personal information, selectively sharing only necessary data. This approach not only protects user privacy but also fosters trust and compliance with global regulations. By leveraging EarthID’s secure and scalable solution, you can efficiently onboard genuine customers while reducing the risks associated with data breaches and fraud.
      </p>
    </OverlapBoxTechno>

    <SectionRight2
        title="How Our Solution Works?"
        desc="EarthID utilizes advanced AI algorithms to verify identities through a series of automated steps, ensuring authenticity and security at every stage. Each uploaded document, such as passports, driver’s licenses, or residence permits, undergoes rigorous checks for validity, authenticity, ownership, and tampering. Anti-spoofing and liveness checks confirm that the individual submitting the document is a live person, not a photo or video."
        titlex="User Journey:"

         title1="Download & Register"
        content1= "Users download the EarthID-powered app and register their EarthID."
        
        title2="ID Verification"
       content2= "Users scan a government-issued photo ID (passport, DL, or residence permit) using the app. The ID is checked for validity, including tamper and fraud detection."

       title3="Selfie & Liveness Check"
        content3= "Users take a selfie for biometric verification, ensuring the person is live and not a static photo or video."
        
        title4="ID Added to Wallet"
        content4= "Once validated, the ID is securely stored in the EarthID wallet for future use."
        
        title5="Info Sharing Flow"
        content5= "Users can securely share their verified credentials by scanning a QR code on a service provider’s portal. The app requests consent and only shares the necessary information based on user selection."
        
        title6="Reusable Identity"
        content6= "Verified credentials can be reused for future interactions, streamlining subsequent verification processes and eliminating the need for repeated document submissions."
        
        imgPath={global1}
        customClass="feature-box-images" // Unique class for the second SectionRight
      ></SectionRight2>

<OverlapBoxFeature title='Features'>
  <FeaturesBox />
</OverlapBoxFeature>
{/* <SectionRight2
        title="Features"
        title1="Global Reach"
        content1= "Supports identity verification of 11000+ documents across more than 220+ countries and territories."
        
        title2="AI-Powered Verification"
       content2= "Proven to deliver speed, convenience, and low friction for your customers resulting in high conversion rates, fraud mitigation, and operational efficiency."
       
       title3="Anti-Spoofing & Liveness Checks"
        content3= "Ensures the identity’s authenticity and confirms users are live, preventing fraud through photo or video submissions."
        
        title4="Multilingual Support"
        content4= "EarthID operates in over 48 languages, ensuring ease of use globally."
        
        title5="Privacy-Preserving Technology"
        content5= "Only the necessary information is shared through selective disclosure."
        
        title6="Reusable ID/KYC"
        content6= "Once verified, credentials can be reused for multiple services and platforms."
        
        title7="No Manual Intervention"
        content7= "Our fully automated verification system ensures efficiency and accuracy without human involvement."
        
        title8="Seamless Integration"
        content8= "Easily integrates into existing systems for automated, secure identity checks."
        
       imgPath={global3}
      ></SectionRight2> */}

      <OverlapBoxIndustry title='Industry Use Cases' className=' overlap-box-industry'>
        {/* <p className='industries-text'>
        Age verification is crucial for businesses that provide age-restricted products and services. 
        Whether you are offering online gaming, adult content, alcohol sales, or any other service that requires age restrictions, verifying the age of your users is a legal necessity. However, traditional age verification methods often require sensitive personal information, which can be a deterrent for users concerned about their privacy. 
        Secure your transactions responsibly and confidently with EarthID&apos;s age verification solutions.
        </p> */}
        <div className='cards-div-industries'>
          <LargeCard
            img={tobacco}
            title='Financial Servicess'
            desc="Simplify KYC and AML compliance by verifying government-issued IDs, enhancing security while reducing onboarding times."
          />
          <LargeCard
            img={gamble}
            title='Healthcare'
            desc="Enable secure patient identity verification for access to medical records and services, maintaining privacy and compliance with healthcare regulations."
          />
          <LargeCard
            img={alcohol}
            title='Telecommunication'
            desc="Streamline customer onboarding with real-time identity verification, ensuring the legitimacy of new subscribers."
          />
          <LargeCard
            img={cbd}
            title='Travel & Hospitality'
            desc="Accelerate the check-in process by securely verifying identities and reducing fraud in bookings and travel services."
          />
          <LargeCard
            img={content}
            title='Government Services'
            desc="Verify citizen identities for accessing government portals, applying for benefits, or completing digital transactions while maintaining data privacy and security."
          />
          <LargeCard
            img={pharma}
            title="Voting & Civic Engagement"
            desc="Facilitate secure, decentralized identity verification for e-voting systems, ensuring voter legitimacy and preventing fraud."
          />
          <LargeCard
            img={diy}
            title='Social Welfare Programs'
            desc='Authenticate recipients of welfare programs to ensure eligibility and prevent fraud while safeguarding personal information.'
          />
          <LargeCard
            img={weapon}
            title='Education & Licensing'
            desc="Verify identities for online education platforms, licensing authorities, and examination bodies, ensuring secure access and legitimate credential issuance."
          />          
        </div>
      </OverlapBoxIndustry>


      <OverlapBoxFeature title='Solution Benefits'>
        <EarthidBox />
    </OverlapBoxFeature>

    <SectionRight
        title="Global Coverage"
        title1="11K+ Documents Supported"
        content1= "EarthID supports over 11,500 identity documents from more than 230 countries and territories. Our extensive global reach allows you to verify your user's age from anywhere in the world."
        
        title2="45+ Languages and Dialects Supported"
       content2= "We have tailored our end-user flows to cater to the world's largest markets, providing support for 45+ languages and dialects. By offering a wide range of languages, we ensure that we can serve a diverse customer base. Our commitment to expanding our language offerings helps us meet the strategic goals of our partners."
        imgPath={global}
      ></SectionRight>
      <Getstarted/>
      <Footer />
    </div>
  );
};


const OverlapBoxTechno = ({ img, title, children }) => {
  return (
    <div className='overlap-box-technoo'>
      <img src={img} alt='phone-img' className='techno-img' />
      <div className='techno-content'>
        <p className='techno-title'>{title}</p>
        <div className='rest-para'>{children}</div>
      </div>
    </div>
  );
};


const OverlapBoxIndustry = props => {
  return (
    <div className='overlap-boxx'>
      <p className='box-title'>{props.title}</p>
      {props.children}
    </div>
  );
};

const LargeCard = ({ img, desc, title }) => {
  return (
    <div className='large-card'>
      <img src={img} className='large-card-img' alt='largeCardImg' />
      <p className='large-card-title'>{title}</p>
      <p className='large-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
    </div>
  );
};

const EarthidBox = props => {
  return (
    <div className='kidv-div'>

      <div className='kys-platform-img-top'>
        <img src={photoOnPhone} className='kys-platform-image' alt='EarthID Image' />
      </div>

      <div className='kys-div'>
      <div className='kys-platform-content'>
        <KYSCard icon={kysBulletPoint} title='Frictionless Onboarding'
          desc='Quickly onboard users with AI-powered verification, maintaining robust security standards without delays.'
        />
        <KYSCard icon={kysBulletPoint} title='Global Compliance'
          desc='EarthID adheres to global regulations like GDPR, DPDP, and CCPA, ensuring seamless global access and compliance.'
        />

        <KYSCard icon={kysBulletPoint} title='Advanced Technology'
          desc="Leverage cutting-edge technologies like AI and distributed ledger technology to enhance security and efficiency."
        />
        <KYSCard icon={kysBulletPoint} title='Cost Savings'
          desc='Reusable IDs and KYC reduce time, costs, and friction in onboarding processes.'
        />
      </div>
      <div className='kys-platform-content'>
        

        <KYSCard icon={kysBulletPoint} title='Scalability'
          desc='Adaptable to businesses of any size and across industries, from small enterprises to large-scale operations.'
        />
        <KYSCard icon={kysBulletPoint} title='Improved Customer Conversion'
          desc='Streamline onboarding processes, reducing customer drop-off and boosting conversion rates.'
        />
        <KYSCard icon={kysBulletPoint} title='Enhanced User Experience'
          desc='Deliver a fast, fully automated, user-friendly verification experience, reducing friction for both businesses and users.'
        />
        <KYSCard icon={kysBulletPoint} title='Trust & Security'
          desc='Inspire user confidence with secure, privacy-preserving identity verification solutions.'
        />
      </div>
      </div>
     
     
    </div>


  );
};

const FeaturesBox = () => {
  return (
    <div className='kidv-div'>
<div className='kys-platform-img-top'>
        <img src={global3} className='kys-platform-image' alt='EarthID Image' />
      </div>
      <div className='kys-div'>
      <div className='kys-platform-content'>
        <KYSCard icon={kysBulletPoint} title='Global Reach'
          desc='Supports identity verification of 11000+ documents across more than 220+ countries and territories.'
        />
        <KYSCard icon={kysBulletPoint} title='AI-Powered Verification'
          desc='Proven to deliver speed, convenience, and low friction for your customers resulting in high conversion rates, fraud mitigation, and operational efficiency.'
        />

        <KYSCard icon={kysBulletPoint} title='Anti-Spoofing & Liveness Checks'
          desc='Ensures the identity’s authenticity and confirms users are live, preventing fraud through photo or video submissions.'
        />
        <KYSCard icon={kysBulletPoint} title='Multilingual Support'
          desc='EarthID operates in over 48 languages, ensuring ease of use globally.'
        />
      </div>

      <div className='kys-platform-content'>
        <KYSCard icon={kysBulletPoint} title='Privacy-Preserving Technology'
          desc='Only the necessary information is shared through selective disclosure.'
        />
        <KYSCard icon={kysBulletPoint} title='Reusable ID/KYC'
          desc='Once verified, credentials can be reused for multiple services and platforms.'
        />
        <KYSCard icon={kysBulletPoint} title='No Manual Intervention'
          desc='Our fully automated verification system ensures efficiency and accuracy without human involvement.'
        />
        <KYSCard icon={kysBulletPoint} title='Seamless Integration'
          desc='Easily integrates into existing systems for automated, secure identity checks.'
        />
      </div>
      </div>
    
    </div>
  );
};


export const KYSCard = ({ icon, desc, title }) => {
  return (
    <div className='kys-card'>
      <div ><img src={icon} className='kdt-card-icon' alt='kysCardIcon' /></div>
      <div className="key-text-col">
        <p className='kys-card-title'>{title}</p>
        <p className='kdt-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    </div>
  );
};

const KDTCard = ({ icon, desc, title }) => {
  return (
    <div className='kys-card'>
      <div ><img src={icon} className='kdt-card-icon' alt='kysCardIcon' /></div>
      <div >
        <p className='kys-card-title'>{title}</p>
        <p className='kdt-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    </div>
  );
};

export const MediumCard = ({ icon, desc, title }) => {
  return (
    <div className='medium-card no-bottom-padding'>
      <img src={icon} className='medium-card-icon-platform' alt='mediumCardIcon' />
      <p className='medium-card-title'>{title}</p>
      <p className='medium-card-desc-platform'>{!desc ? 'right now description is not available' : desc}</p>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-platform'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
}; 

const SectionRight = props => {
  return (
    <div className='feature-box-right'>
      <p className='title-feature'>{props.title}</p>
      <div className="display-flex">
        <div className='feature-box-content'>
          <div className='overallcontent'>
          <p className='title1-feature'>{props.title1}</p>
        <p className='content1-feature'>{props.content1}</p>
        <p className='title1-feature'>{props.title2}</p>
        <p className='content1-feature'>{props.content2}</p>
          </div>
        
        </div>
        <div className='feature-box-image'>
          <img src={props.imgPath} />

        </div>
      </div>
    </div>
  );
};

const SectionRight2 = props => {
  return (
    <div className='feature-box-rights'>
      <p className='title-feature'>{props.title}</p>
      <p className='desc1-feature'>{props.desc}</p>
      <div className="kys-div">
        <div className='kys-platform-content'>

            {/* Conditionally render KDTCard if both title1 and content1 exist */}
            {props.title1 && props.content1 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title1}
                desc={props.content1}
              />
            )}

            {/* Conditionally render KDTCard if both title2 and content2 exist */}
            {props.title2 && props.content2 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title2}
                desc={props.content2}
              />
            )}

            {/* Conditionally render KDTCard if both title3 and content3 exist */}
            {props.title3 && props.content3 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title3}
                desc={props.content3}
              />
            )}

            {/* Conditionally render KDTCard if both title4 and content4 exist */}
            {props.title4 && props.content4 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title4}
                desc={props.content4}
              />
            )}

            {/* Conditionally render KDTCard if both title5 and content5 exist */}
            {props.title5 && props.content5 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title5}
                desc={props.content5}
              />
            )}

            {/* Conditionally render KDTCard if both title6 and content6 exist */}
            {props.title6 && props.content6 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title6}
                desc={props.content6}
              />
            )}

            {/* Conditionally render KDTCard if both title7 and content7 exist */}
            {props.title7 && props.content7 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title7}
                desc={props.content7}
              />
            )}

            {/* Conditionally render KDTCard if both title8 and content8 exist */}
            {props.title8 && props.content8 && (
              <KDTCard
                icon={kysBulletPoint}
                title={props.title8}
                desc={props.content8}
              />
            )}

          </div>
          <div className={`kys-platform-imgdt`}>
          <img src={props.imgPath} className='kys-platform-imageedt' alt="feature-img" />
        </div>
        </div>
       
     
    </div>
  );
};
