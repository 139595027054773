import React from 'react';
import './static-card.styles.scss';
import phone_img_1 from '../../../assest/Home/Significantly-1.png';
import phone_img_2 from '../../../assest/Home/phoneImgNew2.png';
import phone_img_3 from '../../../assest/Home/phoneImgNew3.png';
import web3 from '../../../assest/Home/web3.png';
import rocket from '../../../assest/Home/growth.png';
import aim from '../../../assest/Home/optimization.png';

export const StaticCard = () => (
  <div className='static-card'>
    <div className='static-card_1'>
      <img src={rocket} alt='icon' className='icon' />
      <p className='static-card-text_bold'>
      Grow your business while fighting fraud and achieving compliance
      </p>
      <p className='static-card-text_light'>
      Fraud is evolving, so is EarthID’s robust platform. Expand across new regions without having to worry about growing frauds or changing regulatory landscape. With EarthID’s global data protection and privacy compliant solutions, make borders invisible and stay ahead of the competition.
      </p>
      <img src={phone_img_1} alt='phone__img' className='phone__img' />
    </div>
    <div className='static-card_1'>
      <img src={aim} alt='icon' className='icon' />
      <p className='static-card-text_bold'>
      Optimize processes and improve user experience while driving sustainability
      </p>
      <p className='static-card-text_light'>
      Convert more customers efficiently with our highly automated, frictionless, and user-friendly identity management, authentication and age verification solutions. Dramatically improve efficiency, and drive sustainability, by eliminating physical documents and manual interventions.​
      </p>
      <img src={phone_img_2} alt='phone__img' className='phone__img' />
    </div>
    <div className='static-card_1'>
      <img src={web3} alt='icon' className='icon' />
      <p className='static-card-text_bold'>
      Enable frictionless and secure access to Web 3.0 and Metaverse ecosystems
      </p>
      <p className='static-card-text_light'>
      Provide a frictionless and personalized experience to the users across your Web3 and Metaverse based ecosystems. Empower your users to own and manage their data, credentials, avatars, tokens and other digital assets in a secure and portable manner.
      </p>
      <img src={phone_img_3} alt='phone__img' className='phone__img' />
    </div>
  </div>
);
