import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../../technopage/techno.component';
//import {  OverlapBoxFeature } from '../features-page/features-page.component';
//import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import benefits from '../../../assest/passwordless-soln/MFA T (1).png'
import benefits2 from '../../../assest/passwordless-soln/MFA Benefits (2).webp'
import benefits3 from '../../../assest/passwordless-soln/MFA Standards (1).png'
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import phoneImg from '../../../assest/phoneImg/passwordless authentication-02.png';
import './passwordless.styles.scss';

export const Passwordless = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Passwordless MFA' uris='Passwordless MFA' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Creating A World Without Passwords And A Future Without Data Breaches.'
    >
      <p>
      In today’s digital landscape, passwords are a weak link, frequently targeted by cybercriminals and creating user friction. EarthID’s Passwordless Multi-Factor Authentication (MFA) eliminates passwords, offering secure, seamless authentication using registered devices, biometrics (facial recognition, fingerprints), and cryptographic keys.</p>
<p>EarthID’s Passwordless MFA enhances security by reducing risks like phishing, brute-force attacks, and credential stuffing, while improving user experience by removing password resets.</p>
<p>The shift to passwordless MFA also reduces IT overhead. Organizations no longer need to deal with costly and time-consuming password resets or password-related support tickets. Instead, EarthID’s solution streamlines authentication processes, freeing up IT resources and reducing operational costs.</p>
<p>By adopting EarthID’s passwordless MFA, you’re investing in a future-proof solution that enhances security, boosts user satisfaction, and drives operational efficiency, ensuring that your organization stays ahead in the evolving threat landscape.</p>
</OverlapBoxTechno>
<SectionRight3
        title="Enhance Security with EarthID's Passwordless MFA"
        desc="EarthID’s Passwordless Multi-Factor Authentication (MFA) solution eliminates traditional passwords, offering a variety of secure, user-friendly authentication methods. This comprehensive approach improves security, reduces friction for users, and ensures privacy compliance."
        
        

        title1="ID Wallet with PKI"
        content1= "EarthID’s ID Wallet securely stores public-private key pairs, enabling users to authenticate by signing cryptographic challenges. This wallet-based approach provides robust security through Public Key Infrastructure (PKI) and is designed for advanced users seeking high levels of cryptographic assurance. Ideal for securing sensitive systems, the PKI method ensures no passwords are stored or transmitted, reducing vulnerability to phishing and breaches."
        
        title2="ID Wallet with Decentralized Identity"
       content2= "EarthID supports decentralized identity systems using Decentralized Identifiers (DIDs). This solution allows users to securely store and manage their digital identities, offering privacy-preserving authentication without relying on centralized authorities. With DIDs, users can authenticate themselves by proving their identity without revealing unnecessary personal information, making it particularly beneficial for privacy-conscious applications."

       title3="Platform Authenticator (Face ID, Fingerprint)"
       content3= "EarthID leverages platform authenticators, such as Face ID and Fingerprint, to provide phishing-resistant, seamless authentication. These built-in device biometric methods securely bind credentials to the user’s device, ensuring both security and ease of use. This is ideal for users who prefer biometric login options without the need for additional hardware or complex setup."

       title4="TOTP via Authenticator App"
       content4= "Time-based One-Time Passwords (TOTP) add an extra layer of security by generating codes via popular authenticator apps like Google Authenticator and Authy. EarthID integrates with these apps to provide secure two-factor authentication (2FA) that enhances protection for accounts, especially for sensitive transactions or high-risk scenarios."

       title5="Passkeys (Cloud-Synced)"
       content5= "Passkeys provide a seamless, passwordless login experience across devices by using FIDO2 and WebAuthn standards. These cryptographic keys are securely stored in cloud-based services like iCloud Keychain and Google Password Manager, allowing users to authenticate across multiple devices without needing passwords. This method ensures high security by binding the passkey to the user’s device, preventing phishing and credential theft."

       title6="Magic Links"
       content6= "EarthID offers Magic Links as a simple, secure passwordless option for login. Users receive a one-time-use link in their email, allowing them to log in with a single click. This method is ideal for users who prefer a quick and straightforward login experience while still ensuring security. Magic Links can also be combined with additional MFA layers for higher security environments."

        imgPath={benefits}
        customClass="feature-box-image2" // Unique class for the second SectionRight
      ></SectionRight3>
      <SectionRight2
        title="Why Choose EarthID Passwordless MFA?"

        title1="Phishing-Resistant Security:"
        content1= "EarthID’s MFA solution eliminates passwords, reducing the risks associated with phishing attacks, password reuse, and credential theft."
        
        title2="User-Centric Experience:"
       content2= "EarthID provides multiple options for passwordless authentication, allowing users to select the method that best suits their preferences and needs."

       title3="Privacy-Preserving:"
       content3= "With the integration of Decentralized Identifiers (DIDs), EarthID ensures privacy and compliance with global regulations like GDPR. Users can authenticate and prove their identity without revealing unnecessary personal data, offering a privacy-first approach to authentication."

       title4="Interoperable and Flexible:"
       content4= "EarthID’s Passwordless MFA supports cross-device and cross-platform compatibility, allowing seamless authentication on web, mobile, and across cloud environments. Whether users are on a desktop or mobile device, they can access their accounts without friction."

       title5="Fast Integration with Exceptional Support:"
       content5= "We focus on delivering top-tier customer support and rapid integration, ensuring your team can implement authentication quickly and efficiently, allowing you to stay focused on building and enhancing your product."

        imgPath={benefits2}
        customClass="feature-box-image2" // Unique class for the second SectionRight
      ></SectionRight2>
     <SectionRight3
        title="Industry Standards Followed by EarthID's Passwordless MFA Solution"
        desc="EarthID’s Passwordless MFA solution complies with globally recognized security and privacy standards, ensuring that authentication processes are both secure and privacy-centric. These standards govern the technical implementation, privacy features, and security mechanisms embedded in EarthID’s solution, providing reliable protection for diverse use cases."

        title1="FIDO2 and WebAuthn"
        content1= "EarthID leverages FIDO2 for secure, passwordless authentication, incorporating platform authenticators (such as Face ID, Touch ID), and public-private key cryptography. FIDO2’s framework provides robust security against phishing attacks and eliminates the need for traditional passwords. EarthID also integrates WebAuthn, a key component of FIDO2, to enablee secure, passwordless login via web browsers. It provides phishing-resistant, device-specific authentication by binding cryptographic keys to individual services."
        
        title2="W3C Decentralized Identifiers (DIDs)"
       content2= "Decentralized Identifiers (DIDs) form the foundation of EarthID’s user-centric approach, allowing users to control their identifiers. DIDs are cryptographically secure, enabling secure passwordless authentication and protecting user privacy."

       title3="OAuth 2.0 and OpenID Connect (OIDC)"
       content3= "EarthID uses OAuth 2.0 for third-party authentication via social login, allowing users to authenticate securely via trusted external providers such as Google and Facebook. OpenID Connect (OIDC) builds on OAuth 2.0 to provide identity verification, enabling seamless single sign-on (SSO) across services."

       title4="General Data Protection Regulation (GDPR) Compliance"
       content4= "EarthID’s solution complies with the General Data Protection Regulation (GDPR), ensuring user data is protected and user consent is prioritized. The solution is designed to collect minimal personal information while giving users full control over their data."

       title5="National Institute of Standards and Technology (NIST) Compliance"
       content5= "EarthID’s MFA solution adheres to the NIST SP 800-63B guidelines, which outline recommendations for secure authentication practices, including handling of biometric data and multi-factor authentication."

       title6="eIDAS (Electronic Identification, Authentication and Trust Services)"
       content6= "For the European market, EarthID complies with eIDAS regulations to ensure secure electronic identification across EU borders. This compliance facilitates cross-border authentication for users and services within the EU."

       title7="Privacy by Design"
       content7= "Privacy is at the core of EarthID’s MFA solution, following the Privacy by Design framework. This approach ensures that privacy features—such as data minimization, user consent, and user control—are embedded into the architecture of the solution from the ground up."

        imgPath={benefits3}
        customClass="feature-box-images" // Unique class for the second SectionRight
      ></SectionRight3>
    <Getstarted/>
    <Footer />
  </div>
);

//dafh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-pl'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};

const KYSCard = ({ icon, desc, title }) => {
  return (
    <div className='kys-card'>
      <div ><img src={icon} className='kdt-card-icon' alt='kysCardIcon' /></div>
      <div className="key-text-col">
        <p className='kys-card-title'>{title}</p>
        <p className='kdt-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    </div>
  );
};

// const KDTCard = ({ icon, desc, title }) => {
//   return (
//     <div className='kys-card'>
//       <div ><img src={icon} className='kdt-card-icon' alt='kysCardIcon' /></div>
//       <div >
//         <p className='kys-card-title'>{title}</p>
//         <p className='kdt-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
//       </div>
//     </div>
//   );
// };
// const EarthidBox = props => {
//   return (
//     <div className='kys-div'>
//       <div className='kys-platform-content'>
//         <KYSCard icon={kysBulletPoint} title='ID Wallet with PKI'
//           desc='EarthID’s ID Wallet securely stores public-private key pairs, enabling users to authenticate by signing cryptographic challenges. This wallet-based approach provides robust security through Public Key Infrastructure (PKI) and is designed for advanced users seeking high levels of cryptographic assurance. Ideal for securing sensitive systems, the PKI method ensures no passwords are stored or transmitted, reducing vulnerability to phishing and breaches.'
//         />
//         <KYSCard icon={kysBulletPoint} title='ID Wallet with Decentralized Identity'
//           desc='EarthID supports decentralized identity systems using Decentralized Identifiers (DIDs). This solution allows users to securely store and manage their digital identities, offering privacy-preserving authentication without relying on centralized authorities. With DIDs, users can authenticate themselves by proving their identity without revealing unnecessary personal information, making it particularly beneficial for privacy-conscious applications.'
//         />

//         <KYSCard icon={kysBulletPoint} title='Platform Authenticator (Face ID, Fingerprint)'
//           desc="EarthID leverages platform authenticators, such as Face ID and Fingerprint, to provide phishing-resistant, seamless authentication. These built-in device biometric methods securely bind credentials to the user’s device, ensuring both security and ease of use. This is ideal for users who prefer biometric login options without the need for additional hardware or complex setup."
//         />
        
//       </div>
//       <div className='kys-platform-content'>
       
//       <KYSCard icon={kysBulletPoint} title='TOTP via Authenticator App'
//           desc='Time-based One-Time Passwords (TOTP) add an extra layer of security by generating codes via popular authenticator apps like Google Authenticator and Authy. EarthID integrates with these apps to provide secure two-factor authentication (2FA) that enhances protection for accounts, especially for sensitive transactions or high-risk scenarios.'
//         />
//         <KYSCard icon={kysBulletPoint} title='Passkeys (Cloud-Synced)'
//           desc='Passkeys provide a seamless, passwordless login experience across devices by using FIDO2 and WebAuthn standards. These cryptographic keys are securely stored in cloud-based services like iCloud Keychain and Google Password Manager, allowing users to authenticate across multiple devices without needing passwords. This method ensures high security by binding the passkey to the user’s device, preventing phishing and credential theft.'
//         />
//         <KYSCard icon={kysBulletPoint} title='Magic Links'
//           desc='EarthID offers Magic Links as a simple, secure passwordless option for login. Users receive a one-time-use link in their email, allowing them to log in with a single click. This method is ideal for users who prefer a quick and straightforward login experience while still ensuring security. Magic Links can also be combined with additional MFA layers for higher security environments.'
//         />
//       </div>
//       <div className='kys-platform-img'> <img src={benefits} className='kys-platform-imagee' alt='kysCardIcon' /></div>

//     </div>


//   );
// };


// const KYSCard = ({ icon, desc, title }) => {
//   return (
//     <div className='kys-card'>
//       <div className="key-icon-col"><img src={icon} className='kys-card-icon' alt='kysCardIcon' /></div>
//       <div className="key-text-col">
//         <p className='kys-card-title'>{title}</p>
//         <p className='kys-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
//       </div>
//     </div>
//   );
// };

// const SectionRight = props => {
//   return (
//     <div className='feature-box-rightspl'>
//       <p className='title-feature'>{props.title}</p>
//       <p className='desc1-feature'>{props.desc}</p>
//       <p className='title1-feature'>{props.titlesub}</p>
//           <div className={`feature-box-images ${props.customClass}`}>
//         <img src={props.imgPath} alt="feature-img" />
//       </div>
//       <div className="display-flex">
//         <div className='feature-box-contentpl'>
//           <div className='overallcontent'>
          
//           <p className='title1-feature'>{props.title1}</p>
//         <p className='content1-feature'>{props.content1}</p>
//         <p className='title1-feature'>{props.title2}</p>
//         <p className='content1-feature'>{props.content2}</p>
//         <p className='title1-feature'>{props.title3}</p>
//         <p className='content1-feature'>{props.content3}</p>
       
       
//           </div>
        
//         </div>
//         <div className='feature-box-contentpl'>
//           <div className='overallcontent'>
          
//           <p className='title1-feature'>{props.title4}</p>
//           <p className='content1-feature'>{props.content4}</p>
//         <p className='title1-feature'>{props.title5}</p>
//         <p className='content1-feature'>{props.content5}</p>
//         <p className='title1-feature'>{props.title6}</p>
//         <p className='content1-feature'>{props.content6}</p>
//         <p className='title1-feature'>{props.title7}</p>
//         <p className='content1-feature'>{props.content7}</p>
//           </div>
        
//         </div>
//       </div>
//     </div>
//   );
// };

const SectionRight2 = props => {
  return (
    <div className='feature-box-rights'>
      <p className='title-feature'>{props.title}</p>
      <p className='desc1-feature'>{props.desc}</p>
     
        <div className='kys-div'>
          <div className='kys-platform-content'>
            <p className='title1-feature'>{props.titlesub}</p>

            {/* Conditionally render KDTCard if both title1 and content1 exist */}
            {props.title1 && props.content1 && (
              <KYSCard
                icon={props.icon || kysBulletPoint}
                title={props.title1}
                desc={props.content1}
              />
            )}

            {/* Conditionally render KYSCard if both title2 and content2 exist */}
            {props.title2 && props.content2 && (
              <KYSCard
                icon={props.icon || kysBulletPoint}
                title={props.title2}
                desc={props.content2}
              />
            )}

            {/* Conditionally render KYSCard if both title3 and content3 exist */}
            {props.title3 && props.content3 && (
              <KYSCard
                icon={props.icon || kysBulletPoint}
                title={props.title3}
                desc={props.content3}
              />
            )}

            {/* Conditionally render KYSCard if both title4 and content4 exist */}
            {props.title4 && props.content4 && (
              <KYSCard
                icon={props.icon || kysBulletPoint}
                title={props.title4}
                desc={props.content4}
              />
            )}

            {/* Conditionally render KYSCard if both title5 and content5 exist */}
            {props.title5 && props.content5 && (
              <KYSCard
                icon={props.icon || kysBulletPoint}
                title={props.title5}
                desc={props.content5}
              />
            )}

            {/* Conditionally render KYSCard if both title6 and content6 exist */}
            {props.title6 && props.content6 && (
              <KYSCard
                icon={props.icon || kysBulletPoint}
                title={props.title6}
                desc={props.content6}
              />
            )}

            {/* Conditionally render KYSCard if both title7 and content7 exist */}
            {props.title7 && props.content7 && (
              <KYSCard
                icon={props.icon || kysBulletPoint}
                title={props.title7}
                desc={props.content7}
              />
            )}
          </div>
          <div className={'kys-platform-imgdt'}>
          <img src={props.imgPath} className='kys-platform-imageedt' alt="feature-img" />
        </div>
        </div>

       
     
    </div>
  );
};


const SectionRight3 = props => {
  return (
    <div className='feature-box-rights'>
      <p className='title-feature'>{props.title}</p>
      <p className='desc1-feature'>{props.desc}</p>
      <div className='kys-platform-img-top'>
        <img src={props.imgPath} className='kys-platform-image' alt='EarthID Image' />
      </div>
      <div className="kys-div">
        <div className='kys-platform-content'>
          {props.title1 && props.content1 && (
            <KYSCard
              icon={props.icon || kysBulletPoint}
              title={props.title1}
              desc={props.content1}
            />
          )}
          {props.title2 && props.content2 && (
            <KYSCard
              icon={props.icon || kysBulletPoint}
              title={props.title2}
              desc={props.content2}
            />
          )}
          {props.title3 && props.content3 && (
            <KYSCard
              icon={props.icon || kysBulletPoint}
              title={props.title3}
              desc={props.content3}
            />
          )}
        </div>
        <div className='kys-platform-content'>
          {props.title4 && props.content4 && (
            <KYSCard
              icon={props.icon || kysBulletPoint}
              title={props.title4}
              desc={props.content4}
            />
          )}
          {props.title5 && props.content5 && (
            <KYSCard
              icon={props.icon || kysBulletPoint}
              title={props.title5}
              desc={props.content5}
            />
          )}
          {props.title6 && props.content6 && (
            <KYSCard
              icon={props.icon || kysBulletPoint}
              title={props.title6}
              desc={props.content6}
            />
          )}
        </div>
      </div>

     
    </div>
  );
};
