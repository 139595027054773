import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';

const ResponseModal = ({ handleClose, show, message }) => {
  return (
    <Fragment>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body className="text-center p-4">{message}</Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ResponseModal;
