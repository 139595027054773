import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg from '../../../assest/phoneImg/AI ML.png';
import './ai-ml.styles.scss';

export const AiMl = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='AI/ML' uris='AI/ML' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Scaling Identity Verification Using Artificial Intelligence & Machine Learning.'
    >
      <p>
        With the rise in fraudulent transactions, Artificial Intelligence is becoming prevalent in
        various ID scanning and security solutions. Using Machine Learning and automation, EarthId
        offers an identity verification solution that is robust and doesn’t rely on human
        verification experts.
      </p>
      <p>
        ID authentication provided by Artificial Intelligence relies completely on the collection of
        metadata. This metadata doesn’t contain information about the processed document, instead,
        contains data on the involved processes and their outcomes. This way using Machine Learning
        the software trains itself to detect fraudulent IDs, optimizing, and improving the
        performance and reliability of the verification process. AI & ML provides an edge over
        fraudsters by enhancing the human ability to process information intelligently.
      </p>
      <p>
        Augmented with facial recognition, EarthId’s identity verification solution is built upon
        the right combination of human and artificial intelligence. To know more about our
        AI-enabled solution, talk to our experts.
      </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

//adsfua
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-ai'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Platform / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
