import React from 'react';
import { Route, useRouteMatch, Switch } from 'react-router-dom';
import { Platform } from './platformpage/platform.component';
import { Product } from './productpage/productpage.component';
import './solutionpage.styles.scss';
import { UseCase } from './use-case-page/use-case-page.component';
import { CompliancePage } from './compliance-page/compliance-page.component';
import { AgeVerification } from './age-verification-page/age-verification';
import { DigitalCredentials } from './digital-credentials-page/digital-credentials';
import { DataTokenization } from './data-tokenization/data-tokenization';
import DIDPage from './ssi-page/ssi-page.component';
import { Passwordless } from './passwordless/passwordless.component';
import { IDV } from './idvpage/idv.component';
import { Wallet } from './wallet-page/wallet';

const SolutionPage = () => {
  let { path } = useRouteMatch();
  return (
    <div className='solutionpage'>
      <Switch>
        <Route path={`${path}/platform`} component={Platform} />
        <Route path={`${path}/identity-verification`} component={IDV} />
        <Route path={`${path}/products`} component={Product} />
        <Route path={`${path}/use-case`} component={UseCase} />
        <Route path={`${path}/compliance`} component={CompliancePage} />
        <Route path={`${path}/age-verification`} component={AgeVerification} />
        <Route path={`${path}/digital-credentials`} component={DigitalCredentials} />
        <Route path={`${path}/data-tokenization`} component={DataTokenization} />
        <Route path={`${path}/did`} component={DIDPage} />
        <Route path={`${path}/passwordless`} component={Passwordless} />
        <Route path={`${path}/id-wallet`} component={Wallet} />
      </Switch>
    </div>
  );
};

export default SolutionPage;
