import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg from '../../../assest/phoneImg/OCR.png';
import './ocr.styles.scss';

export const OCR = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf
      title='Optical Character Recognition (OCR)'
      uris='Optical Character Recognition'
    />
    <OverlapBoxTechno
      img={phoneImg}
      title='Improving The Identity Management System Using OCR.'
    >
      <p>
        Whether it is a physical document or in a digital format, Optical Character Recognition
        technology can extract written information from any document. EarthId uses OCR to give users
        an optimal experience by minimizing the input at the user’s end.
      </p>
      <p>
        OCR plays a vital role in the identity verification process by extracting details like Name,
        Date of Birth, Nationality, Address, and Gender, from the provided identity document. Hence,
        substantially reducing the time required to verify documents by eradicating the need for
        human intervention. All a user needs is a webcam to capture and upload the relevant identity
        document to the EarthId portal, our OCR technology takes care of the rest.
      </p>
      <p>
        Experience effortless verification with Optical Character Recognition technology, thereby
        reducing the possibility of human errors in the extraction process. To know more about the
        EarthId platform, get in touch with our experts.
      </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

//asdfad
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-ocr'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Platform / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
