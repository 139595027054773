import React, { useState } from 'react';
import { OverlapWithFilter } from '../../../components/essentials/overlap-box/overlap-with-filters/overlap-with-filters.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import { Getstarted } from '../../../components/essentials/get-started/getstarted.component';
import './blogs.styles.scss';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import moment from 'moment';

export const Blogs = (props) => {
  const { blogs, loading } = props;
  const blogsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
console.log('All blogs', blogs)
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const totalBlogs = blogs.length;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className='blogs-main'>
      <NavBar />
      <HeaderHalf title='Do You Enjoy a Good Read?' uris='Blogs' />
      <OverlapWithFilter>
        <p className='blog-sub-title'>We Would Like To Share Our Passion For Technology And Innovation With You.</p>
        {loading === true ? (
          <h1>Loading the content</h1>
        ) : (
          <>
            <div className="blog-cards">
              {currentBlogs.map((blog) => (
                <BlogCard
                  key={blog.id}
                  title={blog.title}
                  img={blog.coverImage}
                  author={blog.author || 'Admin'}
                  date={moment(blog.publishedAt).format('MMM Do YY')}
                  subject={blog.subject}
                  slug={blog.slug}
                  {...props}
                />
              ))}
            </div>
            <Pagination blogsPerPage={blogsPerPage} totalBlogs={totalBlogs} paginate={paginate} />
          </>
        )}
      </OverlapWithFilter>
      <Getstarted />
      <Footer />
    </div>
  );
};

const Pagination = ({ blogsPerPage, totalBlogs, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalBlogs / blogsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className='paginationn'>
      <ul className='pagination'>
        {pageNumbers.map((number) => (
          <li key={number} className='page-item'>
            <button onClick={() => paginate(number)} className='page-link'>
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const BlogCard = (props) => {
  const { title, img, author, date, subject, slug, history, match } = props;
  const url = process.env.REACT_APP_IMG_BLOG_URL;
  return (
    <div className='blog-div'>
    <a  onClick={() => history.push(`${match.path}${slug}`)}>

      <img src={`${url}${img}`} alt='anImg' className='img-for-blog' />
      {/* <div className='category row'>
      <p className='cat1'>CATEGORY</p>
      <p className='cat1'>3 MIN READ</p>
      </div> */}
      <div className='blog-title-sub'>
      <p className='blog-title'>{title}</p>
      <p className='article'>{subject}</p>
      </div>
      
      <p className='dateAndAuth'>
        <span className='dateField'>{date} </span> | 
        <span className='author-field'>{author}</span>
      </p>
      
      {/* <button className='btn-blog' onClick={() => history.push(`${match.path}${slug}`)}>
        View Blog
      </button> */}
    
    </a>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-blog'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Resources / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
