import React from 'react';
import './features-page.styles.scss';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import autodata from '../../../assest/6 - Solutions - Features/Automated Data Extraction.png';
import facerec from '../../../assest/6 - Solutions - Features/Face Recognition.png';
import global from '../../../assest/6 - Solutions - Features/Global Coverage.png';
import reduced from '../../../assest/6 - Solutions - Features/Reduced Risk, Assured Compliance _ An Enhanced Customer Experience.png';
import swifter from '../../../assest/6 - Solutions - Features/Swifter _ Seamless Authentication.png';
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import photoOnPhone from '../../../assest/6 - Solutions - Features/whatmore.jpg';
import middle from '../../../assest/6 - Solutions - Features/features-middle.jfif';

export const FeaturesPage = () => {
  return (
    <div className='solution-platform'>
      <NavBar />
      <HeaderHalf title='Features' uris='Features' />
      <SectionLeft
        class='feature-box-left padding-up'
        title="Reduced Risk, Assured Compliance & An Enhanced Customer Experience"
        content="EarthID brings to you an extensive enterprise identity verification solution that uses a multi-layer process to access public records to instantly validate the identity of an individual at the same time offering predictive information around the individual’s identity. Thereby, enabling businesses to instantly approve, deny, or escalate the information provided by the applicant."
        imgPath={reduced}
      ></SectionLeft>
      <SectionRight
        title="Swifter & Seamless Authentication"
        content="When relying on identity verification platforms, faster authentication is the key to reduced customer drop-off and checkout abandonment rates. With our to-the-point verification flow, we offer an extraordinary UI/UX experience, allowing the users to perform necessary verification steps quickly."
        imgPath={swifter}
      ></SectionRight>
      <SectionLeft
        class='feature-box-left'
        title="Global Coverage"
        content="Our intelligent identity solution uses state-of-the-art technologies to offer a unique comprehensive KYC solution that verifies identities from all across the globe, paying regards to the regulations and compliance of the country."
        imgPath={global}
      ></SectionLeft>
      <SectionRight
        title="Automated Data Extraction"
        content="We at EarthID believe in simplifying processes and that is what enabled us to use Optical Character Recognition technology to minimize manual processing. EarthID’s platform automatically extracts text information from documents with end-user only uploading their documents."
        imgPath={autodata}
      ></SectionRight>
      <SectionLeft
        class='feature-box-left'
        title="Face Recognition & Liveness Detection"
        content="Our reliable facial recognition and 3D liveness detection feature provide users with a swifter authentication, concurrently strengthening the identity verification process against anti-spoofing efforts."
        imgPath={facerec}
      ></SectionLeft>
        <div className='features-middle-content'>
          <img src={middle} className='features-middle'/>
        </div>
      <OverlapBoxFeature title='What’s More?'>
        <MoreBox />
      </OverlapBoxFeature>
      {/*<img src={earthIdFeature} width="45%" height="15%"/> */}
      <Getstarted/>
      <Footer />
    </div>
  );
};

const SectionLeft = props => {
  return (
    <div className={props.class}>
      <p className='title-feature'>{props.title}</p>
      <div className="display-flex">
        <div className='feature-box-image'>
          <img src={props.imgPath} />
        </div>
        <div className='feature-box-content'>
          {props.content}
        </div>
      </div>
    </div>
  );
};
const SectionRight = props => {
  return (
    <div className='feature-box-right'>
      <p className='title-feature'>{props.title}</p>
      <div className="display-flex">
        <div className='feature-box-content'>
          {props.content}

        </div>
        <div className='feature-box-image'>
          <img src={props.imgPath} />

        </div>
      </div>
    </div>
  );
};
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-features'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Platform / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};


const MoreBox = props => {
  return (
    <div className='kys-div'>
      <div className='kys-platform-content'>
        <MoreCard icon={kysBulletPoint} title='Scalable'
          desc='Built on top of NextGen distributed
          ledger technology, Hashgraph, EarthId is super
          fast and highly scalable.'
        />
        <MoreCard icon={kysBulletPoint} title='Global Identity'
          desc="EarthID is intelligently designed to provide its users with a swift and effortless user-friendly verification process."
        />
        <MoreCard icon={kysBulletPoint} title='Co-relation Resistant'
          desc=' User data shared by the
          verifier or issuer can not be traced back to the
          user.'
        />
      </div>
      <div className='kys-platform-content'>
        <MoreCard icon={kysBulletPoint} title='No DDoS Attack'
          desc='Well known for its built-in
          security feature, Hashgraph is highly resilient to
          distributed denial of service attacks.'
        />
        <MoreCard icon={kysBulletPoint} title='Data Sharing'
          desc='EarthID lets you share data in
          varied ways namely, Direct Share, Selected
          Disclosure, Zero-Knowledge-Proof as well as Self
          Attested Data.'
        />
        <MoreCard icon={kysBulletPoint} title='Immutable'
          desc='Deleting, editing, or replication of
          data in any way or form is not possible with the
          use of Hashgraph technology.'
        />
      </div>
      <div className='kys-platform-img'> <img src={photoOnPhone} className='kys-platform-image' alt='MoreCardIcon' /></div>

    </div>


  );
};


export const MoreCard = ({ icon, desc, title }) => {
  return (
    <div className='kys-card'>
      <div className="key-icon-col"><img src={icon} className='kft-card-icon' alt='moreCardIcon' /></div>
      <div className="key-text-col">
        <p className='kys-card-desc'><strong>{title + ': '}</strong>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    </div>
  );
};

export const OverlapBoxFeature = props => {
  return (
    <div className='overlap-box extend-it whatmore'>
      <p className='box-title'>{props.title}</p>
      {props.children}
    </div>
  );
};