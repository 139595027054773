import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import gplay from '../../../assest/playstore.png';
import iApp from '../../../assest/appstore.png';


import './privacy.styles.scss';

const AppDownloadMasterCard = () => {




  return (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='' uris='' />
    <OverlapBoxPrivacy
      title='Download Apps'
       >
    </OverlapBoxPrivacy>

    <Getstarted/>
    <Footer />
  </div>
  )
  };

export const OverlapBoxPrivacy = ({ header, title, children }) => {
  return (
    <div className='overlap-box-privacy'>
      <div className='privacy-content'>
        <p className='privacy-main-title'>{title}</p>
        <div className='privacy-block'>
        <div>
      <div className='download-app-form'>
        <div>
          
          <div className='app_buttons'>
            <a href="https://drive.google.com/file/d/1-XvqCd1C6RWu90vshpZMAQzfrs01p9Eh/view?usp=sharing" target="BLANK"><img src={gplay} alt='toCncl' className="app-img" /> </a>
            <a href="https://testflight.apple.com/join/71bjcZbu" target="BLANK"><img src={iApp} alt='toCncl' className="app-img" /> </a>
          </div>
        </div>
      </div>
    </div>
        </div>
       

      
       
        
      </div>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-privacy'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        
      </div>
    </div>
  );
}; 
export default AppDownloadMasterCard;