import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import './privacy.styles.scss';

const TermsAndConditions = () => {

const headerContent=`PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY

  BY USING OUR WEBSITE YOU AGREE TO THE TERMS AND CONDITIONS SET OUT BELOW.
  
  IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS YOU SHOULD NOT USE OUR WEBSITE. <br/>
  
  “EarthID”, “we”, “our” or “us” refers to EarthID Technology Ltd., a company incorporated in England and Wales under number 10979596 and having its registered office at International House, 12 Constance St, London E16 2DQ. EarthID is registered with the Information Commissioner’s Office as a data controller with registration number ZA557020. EarthID can also be contacted by email: <a href="mailto:privacy@myearth.id" target="_blank" rel="noreferrer">privacy@myearth.id</a>
  
  These terms and conditions of use (“Web Terms”) apply to our website <a href= "https://myearth.id" target="_blank">https://myearth.id</a> in addition to the Web Privacy and Cookies Policy (“Policy”). These Web Terms do not apply to the use of our App which is subject to our mobile application end-user licence (“Licence”) which is available at <a href="https://myearth.id/mobile-application-end-user-licence" target="_blank">https://myearth.id/mobile-application-end-user-licence</a>`

  return(
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='' uris='' />
    <OverlapBoxPrivacy
      title='TERMS AND CONDITIONS'
      header={headerContent}
    >

    </OverlapBoxPrivacy>

    <Getstarted/>
    <Footer />
  </div>
);
  }

export const OverlapBoxPrivacy = ({ header, title, children }) => {
  return (
    <div className='overlap-box-privacy'>
      <div className='privacy-content'>
        <p className='privacy-main-title'>{title}</p>
        <p className="headerBlock"><div dangerouslySetInnerHTML={{ __html: header }} /></p>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">1. Who We are and What these Terms Do</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>1.1 We, EarthID Technology Ltd., a company incorporated in England and Wales under number 10979596 and having its registered office at International House, 12 Constance St, London E16 2DQ, license you to use our website https://myearth.id in accordance with the Web Terms.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">2. Contact and App Support</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>2.1 If you have any questions about these Web Terms or our website or if you want to learn more about the App or have any problems using them, you can email us at <a href="mailto:terms@myearth.id" target="_blank" rel="noreferrer">terms@myearth.id</a></p>
              <p className='privacy-text-main'>2.2 We will only use your personal information as set out in our <a href='https://myearth.id/privacy-policy' rel="noreferrer" target='_blank'>https://myearth.id/privacy-policy</a></p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">3. How You May Use Material on Our Site</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>3.1 You must be over 18 to accept these Web Terms and to use our website.</p>
              <p className='privacy-text-main'>3.2 In return for your agreeing to comply with these Web Terms you may use our website as set out in these Web Terms.</p>
              <p className='privacy-text-main'>3.3 All intellectual property rights in the website throughout the world belong to us (or our licensors) and the rights in the website are merely licensed to you. You have no intellectual property rights in, or to, the website other than the right to use them in accordance with these Web Terms.</p>
              <p className='privacy-text-main'>3.4 You must not use any part of the content on our website for commercial purposes without obtaining a licence to do so from us</p>
              <p className='privacy-text-main'>3.5 You may print off one copy, and may download extracts, of any page(s) from our website for your personal use.</p>
              <p className='privacy-text-main'>3.6 Our status (and that of any identified contributors) as the authors of content on our website must always be acknowledged.</p>
              <p className='privacy-text-main'>3.7 You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
              <p className='privacy-text-main'>3.8 If you copy or download or use part of our website in breach of these Web Terms, your right to use our website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">4. Information Only Website</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>4.1 The content on our website is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content.</p>
              <p className='privacy-text-main'>4.2 Although we make reasonable efforts to update the content on our website, we make no representations, warranties or guarantees, whether express or implied, that the content on our website is accurate, complete or up to date.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">5. Acceptable Use Restrictions</p>
            <div className='privacy-text'>
              <p><span className='privacy-text-main'>5.1 You must:</span><br/>
              <div className='newIdent'>
5.1.1 not use the website in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the website or any operating system;<br/>

5.1.2 Not infringe our intellectual property rights or those of any third party in relation to your use of the website;<br/>

5.1.3 not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the website;<br/>

5.1.4 not use the website in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users; and<br/>

5.1.5 not collect or harvest any information or data from the website or our systems or attempt to decipher any transmissions to or from the servers running the website.
</div>
</p>
              <p className='privacy-text-main'>5.2 You are responsible for configuring your information technology, computer programmes and platform to access our website. You should use your own virus protection software.</p>
              <p className='privacy-text-main'>5.3 You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our website, the server on which our website is stored or any server, computer or database connected to our website. You must not attack our website via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our website will cease immediately.</p>
              <p className='privacy-text-main'>5.4 “EarthID” is a trade mark registered in each of the UK, China and the United States of America. You are not permitted to use it without our approval, unless it is part of material you are using as permitted under clause 3.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">6. Rules About Linking to Our Website</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>6.1 You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
              <p className='privacy-text-main'>6.2 You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
              <p className='privacy-text-main'>6.3 Our website must not be framed on any other site, nor may you create a link to any part of our website other than the home page.</p>
              <p className='privacy-text-main'>6.4 We reserve the right to withdraw linking permission without notice.</p>
              <p className='privacy-text-main'>6.5 If you wish to link to or make any use of content on our website other than that set out above, please contact us at <a href="mailto:terms@myearth.id" target="_blank" rel="noreferrer">terms@myearth.id</a></p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">7. Operation of Our Website and Updates</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>7.1 We do not guarantee that our website, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our website at any time.</p>
              <p className='privacy-text-main'>7.2 We do not guarantee that our website will be error free or secure or free from bugs or viruses.</p>
              <p className='privacy-text-main'>7.3 Please note that we may need to change these Web Terms from time to time. We will put a notice on our website when any changes are made. You should then read the updated Website Terms again and if you agree to them you may use our website but again if you do not agree to the Website Terms you should not use our website.</p>
              <p className='privacy-text-main'>7.4 We may update and change our website from time to time to reflect changes whether to our Alpha, our App, our users’ needs and our business and other developments.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">8. We Are Not Responsible for Other Website You Access</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>8.1 The website may contain links to third party sites. Such sites are not under our control, and we are not responsible for and have not checked and approved their content, their terms of use or their privacy policies (if any). You will need to make your own

independent judgement about whether to use any such sites, including whether to enter into any transactions to buy any products or services offered by or via them.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">9. Online Purchases</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>9.1. You can make purchases of digital content products from our online store. You must pay for the digital content products before you download them. The contract for the purchase of digital content is concluded as soon as we make the digital content available for download by you. You will then have a licence to use that digital content subject to these terms and conditions and any further amendments or changes notified to you or applicable at the time of purchase.</p>
              <p><span className='privacy-text-main'>9.2. Although we have made every effort to display the colours accurately, we cannot guarantee that your device’s display of the colours will be the same as our display of the products. We may update or require you to update digital content, provided that the digital content shall always match the description of it that we provided to you before you bought it. We may make changes to the products to implement minor technical adjustments and improvements, which will not affect your use of the products. We may have to delay or suspend the supply of digital content to:</span><br/>
              <div className='newIdent'>
9.2.1. deal with technical problems or make minor technical changes;<br/>

9.2.2. update the product to reflect changes in relevant laws and regulatory requirements; and/or<br/>

9.2.3. make changes to the product as requested by you or notified by us to you.
</div>
</p>
              <p>9.3. You can always end your contract with us. Your rights when you end the contract will depend on what you have bought, whether<br/>

there is anything wrong with it, how we are performing and when you decide to end the contract. To end the contract with us, please write to <a href="mailto:terms@myearth.id" target="_blank" rel="noreferrer">terms@myearth.id</a><br/>
<div className='newIdent'>
9.3.1. If what you have bought is faulty or misdescribed you may have a legal right to end the contract (or to get the product repaired or replaced or a service re-performed or to get some or all of your money back as set out below;<br/>

· If your product is digital content, the Consumer Rights Act 2015 says digital content must be as described, fit for purpose and of satisfactory quality:<br/>

· If your digital content is faulty, you’re entitled to a repair or a replacement.<br/>

· If the fault can’t be fixed, or if it hasn’t been fixed within a reasonable time and without significant inconvenience, you can get some or all of your money back.<br/>

· If you can show the fault has damaged your device and we haven’t used reasonable care and skill, you may be entitled to a repair or compensation<br/>

9.3.2. If you want to end the contract because of something we have done or have told you we are going to do as set out below, then the contract will end immediately and we will refund you in full for any products which have not been provided and you may also be entitled to compensation;<br/>

· we have told you about an upcoming change to the product or these terms which you do not agree to;<br/>

· we have told you about an error in the price or description of the product you have ordered and you do not wish to proceed;<br/>

· there is a risk that supply of the products may be significantly delayed because of events outside our control;<br/>

· we have suspended supply of the products for technical reasons, or notify you we are going to suspend them for technical reasons; or<br/>

· you have a legal right to end the contract because of something we have done wrong<br/>

9.3.3. If you have just changed your mind about the product you have a legal right to change your mind within 14 days and receive a refund but not if you have already started to download the digital content, in which case you will not be entitled to a refund. Unlike many other products bought online you do not have the right under the Consumer Contracts Regulations 2013 to change your mind in respect of digital products after you have started to download or stream these; or<br/>

9.3.4. In all other cases (if we are not at fault and there is no right to change your mind), you will not be entitled to a refund.
</div>
</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">10. Our Responsibility for Loss or Damage Suffered by You</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>10.1 If we fail to comply with the Web Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking these Web Terms or our failing to use reasonable care and skill. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time you accepted these terms, both we and you knew it might happen.</p>
              <p className='privacy-text-main'>10.2 The website is for domestic and private use. If you use the website for any commercial, business or resale purpose we will have no liability to you whether for any loss of profit, loss of business, business interruption, or loss of business opportunity or otherwise.</p>
              <p className='privacy-text-main'>10.3 We are not responsible for any loss or damage you suffer as a result of failure by you to comply with the Web Terms. However, we do not exclude or limit in any way our liability to you where it would

be unlawful to do so. This includes liability for fraud and fraudulent misrepresentation and for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors.</p>
              <p className='privacy-text-main'>10.4 If defective digital content that we have supplied damages equipment, a device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</p>
              <p className='privacy-text-main'>10.5 The website has not been developed to meet your individual requirements.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">11. We May Transfer this Agreement to Someone Else</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>11.1 We may transfer our rights and obligations under these Web Terms to another organisation. We will give you notice of any transfer by posting notice on the website.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">12. You Need Our Consent to Transfer Your Rights to Someone Else</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>12.1 You may only transfer your rights or your obligations under these terms to another person if we agree in writing.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">13. No Rights for Third Parties</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>13.1 This agreement does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of this agreement.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">14. If a Court Finds Part of this Contract Illegal, the Rest Will Continue in Force</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>14.1 Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">15. Even if We Delay in Enforcing this Contract, We can Still Enforce it Later</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>15.1 Even if we delay in enforcing this contract, we can still enforce it later. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this contract, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.</p>
            </div>
          </div>
        </div>
        <div className='privacy-block'>
          <div className="privacy-title">
            <p className="header-title">16. Which Laws Apply to these Web Terms</p>
            <div className='privacy-text'>
              <p className='privacy-text-main'>16.1 These Web Terms are governed by English law. If there is any dispute or claim, you or we can bring legal proceedings in respect of these Web Terms in the English courts.</p>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-privacy'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
       
      </div>
    </div>
  );
}; 
export default TermsAndConditions;