import React from 'react';


import { Footer } from '../../components/essentials/footer/footer.component';
import {Getstarted} from '../../components/essentials/get-started/getstarted.component';
import { NavBar } from '../../components/essentials/navbar/navbar.component';
import './error.styles.scss';

const ErrorPage = () => {
  return (
    <div className='homepage'>
    <NavBar />
    <div className='headerHalf-main-compliance'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>Sorry ! Your varification email link is invalid </p>
       
      </div>
    </div>

    <div className='footer'>
      <Getstarted/>
      <Footer />
    </div>
  </div>
  );
};
export default ErrorPage;
