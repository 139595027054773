import React from 'react';
import './industries.styles.scss';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
// import network__43 from '../../../assest/Home/network__43.svg';
import retail from '../../../assest/Icons/Industries/commercial.svg';
import credit_cards from '../../../assest/Icons/Industries/finance.svg';
import social from '../../../assest/Icons/Industries/social-media.svg';
import books from '../../../assest/Icons/Industries/education.svg';
import corporate from '../../../assest/Icons/Industries/corporate.svg';
import doc from '../../../assest/Icons/Industries/healthcare.svg';
import utilities from '../../../assest/Icons/Industries/utilities.svg';
import travel from '../../../assest/Icons/Industries/travel.svg';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';

export const Industries = () => {
  return (
    <div className='solution-platform'>
      <NavBar />
      <HeaderHalf title='Industries' uris='Industries' />
      <OverlapBoxIndustry title='' className=' overlap-box-industry'>
        <p className='industries-text'>
          EarthID aims to empower businesses across various domains in accomplishing their regulatory obligations at the same time eliminating any data threat that is caused due to identity thefts.
          Our identity management solutions help a wide range of companies across industries to increase their revenue with a fast and seamless customer experience.
        </p>
        <div className='cards-div-industries'>
          <LargeCard
            img={retail}
            title='Retails'
            desc="Customer due diligence should be as easy as placing the product into the cart of your desired e-commerce website. This is why EarthID brings you a universal digital ID solution to provide effortless user authentication. Easily validate the identities of users and vendors with EarthID’s identity verification solution."
          />
          <LargeCard
            img={credit_cards}
            title='Financial Services'
            desc="Whether it is document verification or age verification, due diligence in banking or other financial services can be time-consuming. Using the EarthID application you can shorten the time window of verification, from days to minutes. KYC, fraud prevention, claims, and payment processing is all part of our offerings for the Banking, Finance, and Insurance industries."
          />
          <LargeCard
            img={social}
            title='Social Platforms'
            desc="Social Platforms are fun but identity theft is not, so EarthID ensures that signing up on various social networking is swift and easy. EarthID’s intelligent biometric technology not only helps user prevent identity thefts but also comply with regional regulations at the same time."
          />
          <LargeCard
            img={books}
            title='Education'
            desc="Be it address validation or biometric verification, we have got you covered. All you need is to install the EarthID application and experience hassle-free digital onboarding. Degree certificates and professional credentials management are key applications of EarthID in the education sector."
          />
          <LargeCard
            img={corporate}
            title='Corporate'
            desc="Landing onto a job requires one to go through consecutive rounds of screening tests, and background verification shouldn't be one of them. Whether it's an employee or a client, our corporate KYC services offer end-to-end background checks. EarthID offers identity and access management services to all corporate and public sector clients."
          />
          <LargeCard
            img={travel}
            title="Travel and Hospitality"
            desc="A vacation where repeated verification hampers the serenity of your travel spirit isn't a dream vacation.  EarthID lets you experience the thrill of your vacation, without having to worry about hotel id-checks. Travel/accommodation bookings and check-ins are a child’s play with EarthID’s swift identity verification process."
          />
          <LargeCard
            img={doc}
            title='Health Care'
            desc='Amidst a medical emergency, identity verification is the last thing that you would want to prioritize. EarthID ensures that your identity is verified within minutes via Digital ID Wallet. EarthID allows each individual to access health records globally.'
          />
          <LargeCard
            img={utilities}
            title='Utilities'
            desc="EarthID lets you experience the swiftness of a Digital ID Wallet by letting you avail of all basic amenities with a one-step digital verification process. With EarthID get access to unified identities for secure and seamless access to public services. "
          />          
        </div>
      </OverlapBoxIndustry>
      {/* <OverlapBoxIndustry className='ovelapBox-2' title='Success Stories'>
        <div className='large-card-div'>
          <ExtraLargeCard
            icon={network__43}
            desc='Monzo Bank Uses a 
          Customer-Centric Approach'
          />
          <ExtraLargeCard
            icon={network__43}
            desc='Monzo Bank Uses a 
          Customer-Centric Approach'
          />
        </div>
      </OverlapBoxIndustry> */}
      <Getstarted/>
      <Footer />
    </div>
  );
};

const LargeCard = ({ img, desc, title }) => {
  return (
    <div className='largeind-card'>
      <img src={img} className='large-card-img' alt='largeCardImg' />
      <p className='large-card-title'>{title}</p>
      <p className='large-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
    </div>
  );
};

// const ExtraLargeCard = ({ icon, desc }) => {
//   return (
//     <div className='extra-large-card'>
//       <img src={icon} className='extra-large-icon' alt='largeIcon' />
//       <p className='extra-large-card-desc'>{desc}</p>
//       <button className='extra-large-card-button'>Read More</button>
//     </div>
//   );
// };

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-industry'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          Platform / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};

const OverlapBoxIndustry = props => {
  return (
    <div className='overlap-box'>
      <p className='box-title'>{props.title}</p>
      {props.children}
    </div>
  );
};
