import React, { useState, useEffect } from 'react';
import { Header, HeaderOverlap } from '../../components/homepageComponents/header/header';
// import { VedioContent } from '../../components/homepageComponents/vedio/vedio-content.component';
import { StaticCard } from '../../components/homepageComponents/card/static-card.component';
import './homepage.styles.scss';
import network_back_540 from '../../assest/Home/network-back_540.png';
import { FaceId } from '../../components/homepageComponents/face-id/face-id.component';
import { BodyContentCard } from '../../components/homepageComponents/body-content-card/body-content.component';
import { FaceScanTwo } from '../../components/homepageComponents/face-scan-two/face-scan-two.component';
// import { BrandPartners } from '../../components/homepageComponents/brand-partner/brand-partner.component';
import {Getstarted} from '../../components/essentials/get-started/getstarted.component';
import { Footer } from '../../components/essentials/footer/footer.component';
import { NavBar } from '../../components/essentials/navbar/navbar.component';
import { PartnersContent } from '../aboutpage/partners/partners.component';

const HomePage = () => {
  let BASE_URL = process.env.REACT_APP_API_URL;
  const [announcement, setAnnouncement] = useState([]);
  //const [loading, setLoading] = useState(false);
    useEffect(() => {
    const fetchFunc = async () => {
      //setLoading(false);
      const response = await fetch(`${BASE_URL}/announcement`);
      const announcement = await response.json();
      // const items = resJson.data.blogs;
      setAnnouncement(announcement);
      //setLoading(false);
    };
    fetchFunc();
  }, []);
  return (
    <div className='homepage'>
      <NavBar />
      <div className='header'>
        <Header />
        <div className='header-overlap'>
          <HeaderOverlap announcement={announcement} />
        </div>
      </div>
      {/* <div className='vedio-content_space'>
      <VedioContent />
    </div> */}
      <div className='body-content_1'>
        <p className='body-content_1-text'>
          <p className='body-content_1-title'>Experience Excellence With EarthID</p>
          <p className='body-content_1-subtext'>Your Trusted Identity Partner</p>
        </p>
        <div className='body-content_1-card'>
          <StaticCard />
        </div>
        <img src={network_back_540} alt='bg-img-content-1' className='body-content-1_img' />
      </div>
      <div className='face-scan-adobe_1'>
        <FaceId />
      </div>
      <div className='body-content_2'>
        <BodyContentCard />
      </div>
      <div className='face-scan-adobe_2'>
        <FaceScanTwo />
      </div>
      <div className='brand-support'>
        <PartnersContent title="Customers & Partners" classN='logo-partners' />
      </div>
      <div className='footer'>
        <Getstarted/>
        <Footer />
      </div>
    </div>
  )
}

export default HomePage;
